var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticStyle: { "z-index": "10" },
      attrs: {
        id: "app-bar",
        color: "white",
        height: "52",
        light: "",
        app: "",
        elevation: "0",
        "clipped-left": ""
      }
    },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              !_vm.$vuetify.breakpoint.mobile
                ? _c(
                    "v-col",
                    { staticClass: "d-flex", attrs: { cols: "12" } },
                    [
                      _c("brand-dropdown"),
                      _vm._l(_vm.navbars, function(nav) {
                        return _c(
                          "v-btn",
                          {
                            key: "navbar_" + nav.text,
                            staticClass: "nav-item nav-item-link",
                            attrs: { href: nav.link, text: "" }
                          },
                          [
                            _c("i", { staticClass: "mr-1", class: nav.icon }),
                            _vm._v(" " + _vm._s(nav.text) + " ")
                          ]
                        )
                      }),
                      _c("v-spacer"),
                      _vm.isShipnity
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "nav-item nav-item-link",
                              attrs: {
                                href:
                                  "https://blog." + _vm.domainName + "/how-to/",
                                target: "_blank",
                                text: ""
                              }
                            },
                            [_vm._v(" คู่มือ ")]
                          )
                        : _vm._e(),
                      _c("help"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "nav-item nav-item-link nav-icon",
                          attrs: {
                            href: "https://www." + _vm.domainName + "/setting/",
                            text: ""
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-cog")])],
                        1
                      ),
                      _c("account-dropdown", { attrs: { "min-width": 180 } })
                    ],
                    2
                  )
                : _c(
                    "v-col",
                    { staticClass: "d-flex justify-space-between" },
                    [
                      _c("brand-dropdown"),
                      _c("v-spacer"),
                      _c(
                        "v-menu",
                        {
                          staticClass: "nav-dropdown",
                          attrs: {
                            "min-width": "100%",
                            tile: "",
                            "offset-y": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var attrs = ref.attrs
                                var on = ref.on
                                return [
                                  _c(
                                    "v-app-bar-nav-icon",
                                    _vm._g(
                                      _vm._b(
                                        {},
                                        "v-app-bar-nav-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "v-list",
                            {
                              staticClass: "nav-list-dropdown full-width",
                              attrs: { id: "nav-dropdown-mobile" }
                            },
                            [
                              _vm._l(_vm.navbars, function(nav) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: "navbar_" + nav.text,
                                    staticClass: "nav-item nav-item-link",
                                    attrs: { href: nav.link, text: "" }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: nav.icon,
                                            expression: "nav.icon"
                                          }
                                        ],
                                        staticClass: "mr-1",
                                        attrs: { small: "" }
                                      },
                                      [_vm._v(" " + _vm._s(nav.icon) + " ")]
                                    ),
                                    _vm._v(" " + _vm._s(nav.text) + " ")
                                  ],
                                  1
                                )
                              }),
                              _vm.isShipnity
                                ? _c(
                                    "v-list-item",
                                    {
                                      staticClass: "nav-item nav-item-link",
                                      attrs: {
                                        href:
                                          "https://blog." +
                                          _vm.domainName +
                                          "/how-to/",
                                        target: "_blank",
                                        text: ""
                                      }
                                    },
                                    [_vm._v(" คู่มือ ")]
                                  )
                                : _vm._e(),
                              _vm.isShipnity
                                ? _c("help", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "btn",
                                          fn: function(ref) {
                                            var attrs = ref.attrs
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-list-item",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "nav-item nav-item-link nav-icon"
                                                    },
                                                    "v-list-item",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-help")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      298390910
                                    )
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                {
                                  staticClass:
                                    "nav-item nav-item-link nav-icon",
                                  attrs: {
                                    href:
                                      "https://www." +
                                      _vm.domainName +
                                      "/setting/",
                                    target: "_blank"
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-cog")])],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c("account-dropdown", {
                                    attrs: { "min-width": "100%" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "btn",
                                        fn: function(ref) {
                                          var attrs = ref.attrs
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "nav-item nav-item-dropdown justify-space-between",
                                                    attrs: {
                                                      tag: "a",
                                                      text: "",
                                                      block: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.shopName) +
                                                    " "
                                                ),
                                                _c("v-spacer"),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v(" mdi-menu-down ")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }