import store from '@/store'

const ifAuthenticated = (to, from, next) => (store.getters['Auth/userId'] ? next() : next({
  name: 'AccessDenied'
}))

export const hasPermission = (next, permission) => {
  const permissions = store.getters['Auth/permission']
  return permissions[permission] ? next() : next({
    name: 'AccessDenied'
  })
}
export default ifAuthenticated
