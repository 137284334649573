var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex secondary--text text-subtitle-1 mt-1 mb-2" },
    [
      _vm.prependIcon
        ? _c(
            "v-icon",
            _vm._b(
              { staticClass: "mr-2", attrs: { small: "" } },
              "v-icon",
              _vm.$attrs,
              false
            ),
            [_vm._v(" " + _vm._s(_vm.prependIcon) + " ")]
          )
        : _vm._e(),
      _c("span", _vm._b({}, "span", _vm.$attrs, false), [
        _vm._v(" " + _vm._s(_vm.label) + " ")
      ]),
      _vm.required
        ? _c("span", { staticClass: "error--text ml-1" }, [_vm._v("*")])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }