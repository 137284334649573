var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-breadcrumbs", {
    staticClass: "secondary--text px-0",
    attrs: {
      items: [
        {
          text: "app",
          to: "",
          icon: "mdi-cog-outline"
        }
      ].concat(_vm.crumbs),
      large: ""
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-breadcrumbs-item",
              { attrs: { to: item.to, exact: "" } },
              [
                item.icon
                  ? _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { small: "" } },
                      [_vm._v(" " + _vm._s(item.icon) + " ")]
                    )
                  : _vm._e(),
                _vm._v(
                  " " +
                    _vm._s(
                      item.text ? _vm.$t(item.text + ".header") : item.path
                    ) +
                    " "
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }