import gql from 'graphql-tag'

export const CREATE_USER = gql`
  mutation CreateUser ($input: CreateUserInput!) {
    createUser (input: $input) {
      user {
        id
        email
      },
      errors
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser ($input: UpdateUserInput!) {
    updateUser (input: $input) {
      user {
        id
        email
        accessAllStock
        allowedStocks
      },
      errors
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser ($input: DeleteUserInput!) {
    deleteUser (input: $input) {
      clientMutationId
    }
  }
`

export default {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER
}
