var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      staticClass: "nav-dropdown",
      attrs: { "min-width": 150, tile: "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var attrs = ref.attrs
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "nav-item nav-item-brand",
                      attrs: { text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" " + _vm._s(_vm.domainNameOnly) + " ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "v-list",
        { staticClass: "nav-list-dropdown" },
        [
          _c(
            "v-list-item",
            {
              attrs: {
                href: "https://www." + _vm.domainName + "/orders/new",
                link: ""
              }
            },
            [_c("v-list-item-title", [_vm._v(" + Order ")])],
            1
          ),
          _c(
            "v-list-item",
            {
              attrs: {
                href: "https://www." + _vm.domainName + "/expenses/new",
                link: ""
              }
            },
            [_c("v-list-item-title", [_vm._v(" + ค่าใช้จ่าย ")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }