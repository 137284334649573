import gql from 'graphql-tag'

export const GET_PAPERGROUP = gql`
  query ($id: ID!){
    paperGroup (id : $id){
      id
      name
      savedCalculations
      htmlPaper
      papers {
        nodes {
          id
          name
          width
          height
          paperSize
          condition
          canvas
          updatedAt
          active
        }
      }
    }
  }
`
export const GET_PAPERGETSIZE = gql`
  query {
    paperGetSize
  }
`
export const GET_PAPERHTML = gql`
  query ($canvas: JSON, $paperSize: PaperPaperSizeEnum, $height: Int, $width: Int, $itemCount: Int){
    paperTestHtml(canvas: $canvas, paperSize: $paperSize, width: $width, height: $height, itemCount: $itemCount)
  }
`

export default {
  GET_PAPERGROUP,
  GET_PAPERHTML,
  GET_PAPERGETSIZE
}
