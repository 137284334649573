<template>
  <v-app>
    <navbar v-if="userId" />
    <v-navigation-drawer
      v-if="userId"
      v-model="drawer"
      :width="330"
      clipped
      app>
      <sidebar />
    </v-navigation-drawer>
    <v-main
      v-if="userId"
      class="bg-main">
      <v-container
        class="px-6"
        fluid>
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mobile"
            class="mr-3"
            @click="draw()" />
          <breadcrumb />
        </div>
        <router-view />
        <snackbar
          v-bind="snackbar"
          @input="onSnackbarChange($event)" />
        <div
          v-if="mode !== 'PRODUCTION'"
          id="btn-i18n"
          class="d-flex align-center">
          <v-select
            v-model="$i18n.locale"
            style="width:150px;"
            :items="langs"
            hide-details
            single-line
            dense
            outlined>
          </v-select>
          <div>
            <v-btn
              color="red"
              x-small
              rounded
              dark
              block
              @click="i18nJsonDownload('th')">
              Download TH
            </v-btn>
            <v-btn
              color="red"
              x-small
              rounded
              dark
              block
              disabled
              @click="i18nJsonDownload('en')">
              Download EN
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-main>
    <v-overlay
      :value="loading"
      style="z-index: 20;">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Navbar from '@/components/Navbar/Navbar.vue'
import Sidebar from '@/components/Sidebar'
import * as downloadjs from 'downloadjs'
import AuthProvider from '@/resources/AuthProvider'

export default {
  name: 'App',
  components: {
    Navbar,
    Sidebar
  },
  data () {
    return {
      shipnity: process.env.VUE_APP_SHIPNITY,
      mode: process.env.VUE_APP_MODE,
      langs: ['th', 'variable'],
      drawer: true
    }
  },
  computed: {
    ...mapGetters({
      snackbar: 'Notification/snackbar',
      email: 'Setting/email',
      userId: 'Auth/userId',
      loading: 'Notification/isLoading',
      csrf: 'Auth/csrf'
    })
  },
  methods: {
    ...mapActions({
      closeErrorMessage: 'Notification/closeErrorMessage',
      setConfirmDialog: 'Notification/setConfirmDialog'
    }),
    async testCsrf () {
      const res = await AuthProvider.testCsrfToken(this.csrf)
      console.log(res)
    },
    draw () {
      this.drawer = !this.drawer
    },
    onSnackbarChange (value) {
      if (!value) {
        this.closeErrorMessage()
      }
    },
    onDialogChange (value) {
      this.setConfirmDialog({
        active: false,
        value
      })
    },
    i18nJsonDownload (locale) {
      if (this.mode === 'DEV') {
        downloadjs(`/locales/${locale}/${ this.$route.name.toLocaleLowerCase() }.json`)
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/scss/styles.scss';
  html {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  #btn-i18n {
    position: fixed;
    background-color: #ffffff;
    z-index: 12;
    top: 10px;
    right: 350px;
  }
  .bg-main {
    // background: #F1F2F5;
    // height: 100vh;
    // overflow-y: auto;
  }
  #app-bar .v-toolbar__content {
    padding: 0 !important;
    border-bottom: 1px solid #f5f5f5 !important;
  }
  #app-bar .container--fluid {
    padding: 0 6px;
    width: 92%;
  }
  #app-bar .nav-item {
    display: flex !important;
    font-family: 'prompt';
    color: #66757f;
    outline: 0;
    text-decoration: none;
    display: inline-block;
    padding: 12px;
    height: 100%;
    min-width: 62px;
    text-transform: none !important;
    letter-spacing: normal;
  }
  .v-menu__content {
    margin: 0 !important;
    box-shadow: 0px 0px 5px rgba(86,96,117,0.2) !important;
  }
  .nav-list-dropdown {
    margin: 0 6px;
  }
  .nav-list-dropdown .v-list-item {
    min-height: 32px;
    text-transform: none !important;
  }
  #nav-dropdown-mobile.nav-list-dropdown .nav-item-link:hover,
  #nav-dropdown-mobile.nav-list-dropdown .nav-item-link:hover .v-icon {
    color: #3B94D9 !important;
  }
  #nav-dropdown-mobile.nav-list-dropdown .nav-item-link:not(.nav-icon):hover {
    text-decoration: underline;
  }
  #nav-dropdown-mobile.nav-list-dropdown .nav-item-dropdown:hover {
    color: #3B94D9 !important;
  }
  #nav-dropdown-mobile.nav-list-dropdown .v-list-item  {
    color: #66757f !important;
    padding: 6px 30px;
    font-size: 17px;
  }
  .nav-list-dropdown.full-width .nav-item-dropdown {
    color: #66757f !important;
    font-size: 17px;
    line-height: 17px;
    font-weight: 400;
    padding: 0 !important;
    text-transform: none !important;
  }
  .nav-list-dropdown .v-list-item__title {
    font-size: 13px;
  }
  #app-bar .nav-dropdown:hover {
    color: #1b1e24;
    text-decoration: none;
    background-color: #eff2f3;
    background-image: none;
  }
  #app-bar .nav-item.nav-item-brand {
    text-transform: capitalize !important;
    font-size: 19px;
    line-height: 19px;
    font-weight: 500;
  }
  #app-bar .nav-item-link {
    font-size: 17px;
    line-height: 17px;
    font-weight: 400;
  }
  #app-bar .nav-item-dropdown {
    font-size: 17px;
    line-height: 17px;
    font-weight: 400;
    padding: 0;
    text-transform: none !important;
  }
  #app-bar .nav-item-link.nav-icon {
    min-width: 20px;
  }
  #app-bar .nav-item-link.nav-icon .v-icon{
    font-size: 20px;
  }
  #app-bar .nav-item-link.nav-icon:hover {
    text-decoration: none;
  }
  #app-bar .nav-item-link:hover {
    color: #3B94D9;
    text-decoration: underline;
  }
  .v-btn {
    letter-spacing: 0.0342313em;
    text-transform: capitalize;
    font-weight: 400;
  }

  .v-btn--outlined {
    background-color: #ffffff;
  }

  .app-card {
    &.v-card {
      // padding: 4px 8px;
    }
  }

  .app-tab {
    .v-tab {
      background-color: #ECECEC;
      margin-right: 8px;
      padding: 0 40px;
      letter-spacing: 0.0342313em;
    }

    .v-tab.v-tab--active {
      background-color: #FFFFFF;
    }

    .v-tabs-slider-wrapper {
      height: 3px !important;
    }

    .v-tabs-slider {
      width: 90%;
      margin: 0 auto;
    }
  }

  .app-table {
    margin-bottom: 12px;

    &.v-data-table>.v-data-table__wrapper>table>thead>tr>th:first-child,
    &.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
      padding-left: 4rem;
    }

    &.v-data-table>.v-data-table__wrapper>table>thead>tr>th:last-child,
    &.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:last-child {
      padding-right: 2rem;
    }

    &.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
      background-color: #F6F6F6;
      letter-spacing: 0;
      font-weight: 500;
      font-size: 14px;
    }

    &.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    &.v-data-table>.v-data-table__wrapper>table>thead>tr>th.simple-table-loader {
      padding: 0;
      height: 100%;
      border: 0;
      background-color: #ffffff;
    }
  }

  // multi select
  .v-menu__content {
    margin: 14px;
    background-color: #ffffff;
  }
  .v-list-item__content {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .v-list-item__action {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: 8px !important;
    margin-left: 16px;

  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .dialog-close-tr {
    right: -3px;
    top: -10px;
  }
  .app-style.v-select .v-select__selection--comma {
    color: #6C6C6C !important;
    font-size: 14px;
  }
  .app-style.v-text-field--outlined fieldset{
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .v-btn:not(.v-btn--round).v-size--small {
    min-width: 32px;
  }
</style>
