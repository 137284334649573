import gql from 'graphql-tag'

export const UPDATE_SETTING = gql`
  mutation UpdateSetting ($input: UpdateSettingInput!) {
    updateSetting (input: $input) {
      setting {
        id
        salePageBanner1Url
        salePageBanner2Url
        logoUrl
      }
      errors
    }
  }
`

export const SUBMIT_JT_REGISTER = gql`
  mutation JtRegister ($input: JtRegisterInput!) {
    jtRegister (input: $input) {
      output
    }
  }
`

export default {
  UPDATE_SETTING,
  SUBMIT_JT_REGISTER
}
