import gql from 'graphql-tag'

export const GET_RESELLER = gql`
  query ($id: ID!) {
    reseller(id: $id) {
      id
      name
      pictureUrl
      resellerCode
      tier {
        id
        name
      }
      tel
      lineId
      facebook
      instagram
      resellerAddress
      taxId
      users {
        id
        email
        shopType
      }
    }
  }
`

export default {
  GET_RESELLER
}
