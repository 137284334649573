import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import store from '@/store'
import { setContext } from 'apollo-link-context'
import _ from 'lodash'

Vue.use(VueApollo)

const httpLink = createUploadLink({
  uri: process.env.VUE_APP_API,
  credentials: 'include'
})

const authLink = setContext(({ headers }) => {
  const token = store.getters['Auth/csrf']
  return {
    headers: {
      ...headers,
      'X-CSRF-Token': token || null
    }
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const setLoading = (isLoading) => {
  store.dispatch('Notification/setLoading', isLoading)
}
const debounceLoading = _.debounce(setLoading, 150)
const apollo = new VueApollo({
  defaultClient: apolloClient,
  watchLoading (isLoading) {
    debounceLoading(isLoading)
  },
  errorHandler (error) {
    store.dispatch('Notification/showErrorMessage', {
      type: 'error',
      message: `ไม่สามารถเชื่อมต่อกับเซิฟเวอร์ได้ (${error.message})`
    })
  }
})

export default apollo
