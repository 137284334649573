const state = {
  theme: 'light'
}

const mutations = {
  SET_THEME (state, payload) {
    state.theme = payload
  }
}

const actions = {
  setTheme ({ commit }, payload) {
    commit('SET_THEME', payload)
  }
}

const getters = {
  theme: (state) => state.theme
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
