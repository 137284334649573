import gql from 'graphql-tag'

export const GET_STOCKS = gql`
  query ($page: Int, $perPage: Int) {
    stocks (page: $page, perPage: $perPage) {
      nodes {
        id
        name
        isMain
        lazadaAuth
        shopeeAuth
        shopeeShopId
        lazadaAccessToken
        lazadaLoginDayLeft
        shopeeShopName
      }
      nodesCount
      pagesCount
    }
  }
`

export default {
  GET_STOCKS
}
