import gql from 'graphql-tag'

export const CREATE_TIER = gql`
  mutation CreateTier ($input: CreateTierInput!) {
    createTier (input: $input) {
      tier {
        id
      },
      errors
    }
  }
`

export const CREATE_TIER_PRODUCT = gql`
  mutation CreateTierProduct ($input: CreateTierProductInput!) {
    createTierProduct (input: $input) {
      tierProduct {
        id
      },
      errors
    }
  }
`

export const CREATE_QUANTITY_PRICE_TIER_PRODUCT = gql`
  mutation CreateQuantityPriceTierProduct ($input: CreateQuantityPriceTierProductInput!) {
    createQuantityPriceTierProduct (input: $input) {
      tierProduct {
        id
      },
      errors
    }
  }
`

export const UPDATE_TIER = gql`
  mutation UpdateTier ($input: UpdateTierInput!) {
    updateTier (input: $input) {
      tier {
        id
        name
      },
      errors
    }
  }
`

export const UPDATE_QUANTITY_PRICE_TIER_PRODUCT = gql`
  mutation UpdateQuantityPriceTierProduct ($input: UpdateQuantityPriceTierProductInput!) {
    updateQuantityPriceTierProduct (input: $input) {
      tierProduct {
        id
      },
      errors
    }
  }
`

export const IMPORT_TIER_ALLPRODUCT = gql`
  mutation TierImportAllProduct ($input: TierImportAllProductInput!) {
    tierImportAllProduct (input: $input) {
      tier {
        id
      }
    }
  }
`
export const IMPORT_TIER_EXCEL = gql`
  mutation TierImportExcel ($input: TierImportExcelInput!) {
    tierImportExcel (input: $input) {
      tier {
        id
      }
    }
  }
`

export const UPDATE_TIER_PRODUCT = gql`
  mutation UpdateTierProduct ($input: UpdateTierProductInput!) {
    updateTierProduct (input: $input) {
      tierProduct {
        id
      },
      errors
    }
  }
`
export const UPDATE_ALLRESELLERINTIER = gql`
  mutation UpdateAllResellerInTier ($input: UpdateAllResellerInTierInput!) {
    updateAllResellerInTier (input: $input) {
      tier {
        id
      }
    }
  }
`

export const DELETE_TIER = gql`
  mutation DeleteTier ($input: DeleteTierInput!) {
    deleteTier (input: $input) {
      clientMutationId
    }
  }
`
export const DELETE_TIER_PRODUCT = gql`
  mutation DeleteTierProduct ($input: DeleteTierProductInput!) {
    deleteTierProduct (input: $input) {
      clientMutationId
    }
  }
`

export const DELETE_QUANTITY_PRICE_TIER_PRODUCT = gql`
  mutation DeleteQuantityPriceTierProduct ($input: DeleteQuantityPriceTierProductInput!) {
    deleteQuantityPriceTierProduct (input: $input) {
      clientMutationId
    }
  }
`

export default {
  CREATE_TIER,
  CREATE_TIER_PRODUCT,
  CREATE_QUANTITY_PRICE_TIER_PRODUCT,
  UPDATE_TIER,
  UPDATE_QUANTITY_PRICE_TIER_PRODUCT,
  IMPORT_TIER_ALLPRODUCT,
  IMPORT_TIER_EXCEL,
  UPDATE_TIER_PRODUCT,
  UPDATE_ALLRESELLERINTIER,
  DELETE_TIER,
  DELETE_TIER_PRODUCT,
  DELETE_QUANTITY_PRICE_TIER_PRODUCT
}
