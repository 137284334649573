const state = {
  paperBuilderId: 'create',
  paperBuilderDrop: null,
  paperBuilderActivatedId: []
}

const mutations = {
  SET_PAPERBUILDERID (state, payload) {
    state.paperBuilderId = payload
  },
  SET_PAPERBUILDERDROP (state, payload) {
    state.paperBuilderDrop = payload
  },
  SET_PAPERBUILDERACTIVATEDID (state, payload) {
    state.paperBuilderActivatedId = payload
  }
}

const actions = {
  setPaperBuilderId ({ commit }, payload) {
    commit('SET_PAPERBUILDERID', payload)
  },
  setPaperBuilderDrop ({ commit }, payload) {
    commit('SET_PAPERBUILDERDROP', payload)
  },
  setPaperBuilderActivatedId ({ commit }, payload) {
    commit('SET_PAPERBUILDERACTIVATEDID', payload)
  }
}

const getters = {
  paperBuilderId: (state) => state.paperBuilderId,
  paperBuilderDrop: (state) => state.paperBuilderDrop,
  paperBuilderActivatedId: (state) => state.paperBuilderActivatedId
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
