var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.userId ? _c("navbar") : _vm._e(),
      _vm.userId
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { width: 330, clipped: "", app: "" },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [_c("sidebar")],
            1
          )
        : _vm._e(),
      _vm.userId
        ? _c(
            "v-main",
            { staticClass: "bg-main" },
            [
              _c(
                "v-container",
                { staticClass: "px-6", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _vm.$vuetify.breakpoint.mobile
                        ? _c("v-app-bar-nav-icon", {
                            staticClass: "mr-3",
                            on: {
                              click: function($event) {
                                return _vm.draw()
                              }
                            }
                          })
                        : _vm._e(),
                      _c("breadcrumb")
                    ],
                    1
                  ),
                  _c("router-view"),
                  _c(
                    "snackbar",
                    _vm._b(
                      {
                        on: {
                          input: function($event) {
                            return _vm.onSnackbarChange($event)
                          }
                        }
                      },
                      "snackbar",
                      _vm.snackbar,
                      false
                    )
                  ),
                  _vm.mode !== "PRODUCTION"
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { id: "btn-i18n" }
                        },
                        [
                          _c("v-select", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              items: _vm.langs,
                              "hide-details": "",
                              "single-line": "",
                              dense: "",
                              outlined: ""
                            },
                            model: {
                              value: _vm.$i18n.locale,
                              callback: function($$v) {
                                _vm.$set(_vm.$i18n, "locale", $$v)
                              },
                              expression: "$i18n.locale"
                            }
                          }),
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "red",
                                    "x-small": "",
                                    rounded: "",
                                    dark: "",
                                    block: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.i18nJsonDownload("th")
                                    }
                                  }
                                },
                                [_vm._v(" Download TH ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "red",
                                    "x-small": "",
                                    rounded: "",
                                    dark: "",
                                    block: "",
                                    disabled: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.i18nJsonDownload("en")
                                    }
                                  }
                                },
                                [_vm._v(" Download EN ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-overlay",
        { staticStyle: { "z-index": "20" }, attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }