import Vue from 'vue'
import Snackbar from '@/components/Snackbar'
import Modal from '@/components/Modal'
import Breadcrumb from '@/components/Breadcrumb'
import TextFieldLabel from '@/components/Label/TextFieldLabel.vue'
import TitleLabel from '@/components/Label/TitleLabel.vue'
import HeaderLabel from '@/components/Label/HeaderLabel.vue'
import ToggleForm from '@/components/Form/ToggleForm.vue'
import TextFieldForm from '@/components/Form/TextFieldForm.vue'
import SelectForm from '@/components/Form/SelectForm.vue'
import SelectMultiGroup from '@/components/Form/SelectMultiGroup.vue'
import SaveChangeButton from '@/components/Button/SaveChangeButton.vue'
// import vClickOutside from 'v-click-outside'

Vue.component('modal', Modal)
Vue.component('snackbar', Snackbar)
Vue.component('breadcrumb', Breadcrumb)
Vue.component('text-field-label', TextFieldLabel)
Vue.component('title-label', TitleLabel)
Vue.component('header-label', HeaderLabel)
Vue.component('toggle-form', ToggleForm)
Vue.component('text-field-form', TextFieldForm)
Vue.component('select-form', SelectForm)
Vue.component('select-multi-group', SelectMultiGroup)
Vue.component('save-change-button', SaveChangeButton)
// Vue.use(vClickOutside)
