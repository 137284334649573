import gql from 'graphql-tag'

export const GET_TIER = gql`
  query ($id: ID!) {
    tier(id: $id) {
      id
      name
      stock {
        id
      }
      productImportExcel
    }
  }
`

export const GET_TIER_PRODUCTS = gql`
  query ($q: String, $id: ID!, $page: Int, $perPage: Int)  {
    tier(id: $id) {
      id
      name
      tierProducts (
        page: $page,
        perPage: $perPage
        filter: { 
          searchAll: $q
        }
      )  {
        nodes {
          id
          resellerPrice
          product {
            id
            code
            name
            price
          }
          quantityPrices {
            id
            price
            quantity
          }
        }
        nodesCount
        pagesCount
      }
    }
  }
`

export default {
  GET_TIER,
  GET_TIER_PRODUCTS
}
