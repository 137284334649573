const snackbarDefault = {
  type: null,
  value: false,
  message: 'No message.',
  multiLine: true,
  timeout: 5000
}

const state = {
  snackbar: snackbarDefault,
  isLoading: false
}

const mutations = {
  SET_SNACKBAR (state, payload) {
    state.snackbar = {
      ...snackbarDefault,
      ...state.snackbar,
      ...payload
    }
  },
  SET_LOADING (state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  setLoading ({ commit }, payload) {
    commit('SET_LOADING', payload)
  },
  setSnackbar ({ commit }, payload) {
    commit('SET_SNACKBAR', {
      ...snackbarDefault,
      ...payload
    })
  },
  showErrorMessage ({ commit }, payload) {
    commit('SET_SNACKBAR', {
      ...payload,
      value: true
    })
  },
  closeErrorMessage ({ commit }) {
    commit('SET_SNACKBAR', {
      value: false
    })
  }
}

const getters = {
  snackbar: (state) => state.snackbar,
  isLoading: (state) => state.isLoading
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
