<template>
  <v-select
    :value="value"
    :items="items"
    :item-value="itemValue"
    :item-text="itemText"
    v-bind="$attrs"
    dense
    hide-details
    outlined
    multiple
    v-on="$listeners">
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @click="toggle()">
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ labelSelectAll }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:selection="{ item, index }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on">
            <span
              v-if="index === 0"
              class="mr-1">{{ item[itemText] || itemText(item) }}</span>
            <span
              v-if="index === 1"
              class="grey--text caption">
              (+{{ value.length - 1 }} others)
            </span>
          </div>
        </template>
        <div
          v-for="(select, i) in value"
          :key="i">
          {{ select[itemText] || itemText(select) }}
        </div>
      </v-tooltip>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: [String, Array, Function],
      required: true
    },
    itemText: {
      type: [String, Array, Function],
      required: true
    },
    labelSelectAll: {
      type: String,
      default: () => 'ใช้ทั้งหมด'
    }
  },
  data: () => ({
    selectedItems: []
  }),
  computed: {
    likesAllItem () {
      return this.value.length === this.items.length
    },
    likesSomeItem () {
      return this.value.length > 0 && !this.likesAllItem
    },
    icon () {
      if (this.likesAllItem) return 'mdi-close-box'
      if (this.likesSomeItem) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.likesAllItem) {
          this.$emit('input', [])
        } else {
          this.$emit('input', this.items.slice())
        }
      })
    }
  }
}
</script>

<style>
</style>

<style scoped>
</style>
