import OrderTitle from '@/assets/svg/IconTitle/Order.svg'
import LanguageTitle from '@/assets/svg/IconTitle/Language.svg'
import CoinTitle from '@/assets/svg/IconTitle/Coin.svg'
import PackageTitle from '@/assets/svg/IconTitle/Package.svg'
import SettingTitle from '@/assets/svg/IconTitle/Setting.svg'
import StockTitle from '@/assets/svg/IconTitle/Stock.svg'
import CreditCardTitle from '@/assets/svg/IconTitle/Creditcard.svg'
import CrmTitle from '@/assets/svg/IconTitle/CRM.svg'
import TransportTitle from '@/assets/svg/IconTitle/Transport.svg'
import ConnectTransportTitle from '@/assets/svg/IconTitle/ConnectTransport.svg'
import LinkTitle from '@/assets/svg/IconTitle/Link.svg'


const title = {
  orderTitle: {
    component: OrderTitle
  },
  languageTitle: {
    component: LanguageTitle
  },
  coinTitle: {
    component: CoinTitle
  },
  packageTitle: {
    component: PackageTitle
  },
  settingTitle: {
    component: SettingTitle
  },
  stockTitle: {
    component: StockTitle
  },
  creditCardTitle: {
    component: CreditCardTitle
  },
  crmTitle: {
    component: CrmTitle
  },
  transportTitle: {
    component: TransportTitle
  },
  connectTransportTitle: {
    component: ConnectTransportTitle
  },
  linkTitle: {
    component: LinkTitle
  },
}

export default title