import gql from 'graphql-tag'

export const CREATE_SHOPEEPRODUCT = gql`
  mutation ShopeeImportProduct ($input: ShopeeImportProductInput!) {
    shopeeImportProduct (input: $input) {
      stock {
        id
      },
      errors
    }
  }
`
export default {
  CREATE_SHOPEEPRODUCT
}
