import _ from 'lodash'
import button from '@/plugins/SvgIcon/ButtonIcon'
import pages from '@/plugins/SvgIcon/PagesIcon'
import pageHeader from '@/plugins/SvgIcon/PageHeader'
import title from '@/plugins/SvgIcon/TitleIcon'
import grey from '@/plugins/SvgIcon/GreyIcon'
import white from '@/plugins/SvgIcon/WhiteIcon'
import dialogIcon from '@/plugins/SvgIcon/DialogIcon'
import paper from '@/plugins/SvgIcon/Paper'

export default _.defaultsDeep(button, pages, pageHeader, title, grey, white, dialogIcon, paper)
