const initialState = {
  currentUser: {
    email: ''
  },
  shop: {
    name: '',
    salePageUrl: '',
    userCount: 0,
    maxUser: 0
  },
  receipt: {
    receiptUpdateA4A5Avatar: null,
    receiptUpdate8CMAvatar: null,
    boxA4A5Avatar: null,
    box8CMAvatar: null,
    boxDropoffAvatar: null
  }
}

const mutations = {
  SET_CURRENT_USER (state, payload) {
    state.currentUser = payload
  },
  SET_SHOP (state, payload) {
    state.shop = payload
  },
  SET_RECEIPT (state, payload) {
    state.receipt = payload
  }
}

const actions = {
  setEmail ({ commit, state }, payload) {
    commit('SET_CURRENT_USER', {
      ...state.currentUser,
      email: payload
    })
  },
  setSalePageUrl ({ commit, state }, payload) {
    commit('SET_SHOP', {
      ...state.shop,
      salePageUrl: payload
    })
  },
  setShopName ({ commit, state }, payload) {
    commit('SET_SHOP', {
      ...state.shop,
      name: payload
    })
  },
  setUserCount ({ commit, state }, payload) {
    commit('SET_SHOP', {
      ...state.shop,
      userCount: payload
    })
  },
  setMaxUser ({ commit, state }, payload) {
    commit('SET_SHOP', {
      ...state.shop,
      maxUser: payload
    })
  },
  setReceiptUpdateA4A5Avatar ({ commit, state }, payload) {
    commit('SET_RECEIPT', {
      ...state.shop,
      receiptUpdateA4A5Avatar: payload
    })
  },
  setReceiptUpdate8CMAvatar ({ commit, state }, payload) {
    commit('SET_RECEIPT', {
      ...state.shop,
      receiptUpdate8CMAvatar: payload
    })
  },
  setBoxA4A5Avatar ({ commit, state }, payload) {
    commit('SET_RECEIPT', {
      ...state.shop,
      boxA4A5Avatar: payload
    })
  },
  setBox8CMAvatar ({ commit, state }, payload) {
    commit('SET_RECEIPT', {
      ...state.shop,
      box8CMAvatar: payload
    })
  },
  setBoxDropoffAvatar ({ commit, state }, payload) {
    commit('SET_RECEIPT', {
      ...state.shop,
      boxDropoffAvatar: payload
    })
  }
}

const getters = {
  currentUser: (state) => state.currentUser,
  shop: (state) => state.shop,
  email: (state) => state.currentUser.email,
  salePageUrl: (state) => state.shop.salePageUrl,
  shopName: (state) => state.shop.name,
  userCount: (state) => state.shop.userCount,
  maxUser: (state) => state.shop.maxUser,
  receipt: (state) => state.receipt
}

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters
}
