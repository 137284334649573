var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    _vm._b(
      {
        staticClass: "nav-dropdown",
        attrs: { tile: "", "offset-y": "" },
        scopedSlots: _vm._u(
          [
            {
              key: "activator",
              fn: function(ref) {
                var attrs = ref.attrs
                var on = ref.on
                return [
                  _vm._t(
                    "btn",
                    [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "nav-item nav-item-dropdown",
                              attrs: { text: "" }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(" " + _vm._s(_vm.shopName) + " "),
                          _c("v-spacer"),
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-menu-down ")
                          ])
                        ],
                        1
                      )
                    ],
                    { attrs: attrs, on: on }
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      },
      "v-menu",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-list",
        { staticClass: "nav-list-dropdown" },
        [
          _vm.isShipnity
            ? _c(
                "v-list-item",
                {
                  attrs: {
                    href: "https://blog." + _vm.domainName + "/how-to/",
                    target: "_blank",
                    link: ""
                  }
                },
                [_c("v-list-item-title", [_vm._v(" คู่มือ ")])],
                1
              )
            : _vm._e(),
          _vm.isShipnity
            ? _c(
                "v-list-item",
                {
                  attrs: {
                    href: "https://package." + _vm.domainName + "/?from=pro",
                    link: ""
                  }
                },
                [_c("v-list-item-title", [_vm._v("สมัครแพ็คเกจ")])],
                1
              )
            : _vm._e(),
          _vm.isShipnity
            ? _c(
                "v-list-item",
                {
                  attrs: {
                    href:
                      "https://www." +
                      _vm.domainName +
                      "/priceplan/buy/buy_sms",
                    link: ""
                  }
                },
                [_c("v-list-item-title", [_vm._v("เติม Credit SMS")])],
                1
              )
            : _vm._e(),
          _c("v-divider", { staticClass: "my-1" }),
          _c(
            "v-list-item",
            {
              attrs: {
                href: "https://www." + _vm.domainName + "/users/edit",
                link: ""
              }
            },
            [_c("v-list-item-title", [_vm._v("ตั้งค่าบัญชี")])],
            1
          ),
          _c(
            "v-list-item",
            {
              attrs: { link: "" },
              on: {
                click: function($event) {
                  return _vm.logout()
                }
              }
            },
            [_c("v-list-item-title", [_vm._v(" ออกจากระบบ ")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }