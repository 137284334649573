import OrderIcon from '@/assets/svg/ShopSettingPage/Order.svg'
import LanguuageIcon from '@/assets/svg/ShopSettingPage/Language.svg'
import PackingIcon from '@/assets/svg/ShopSettingPage/Packing.svg'
import WarehouseIcon from '@/assets/svg/ShopSettingPage/Warehouse.svg'
import ProductIcon from '@/assets/svg/ShopSettingPage/Product.svg'
import PaymentIcon from '@/assets/svg/ShopSettingPage/Payment.svg'
import TaxinvoiceIcon from '@/assets/svg/ShopSettingPage/Taxinvoice.svg'
import PromocodeIcon from '@/assets/svg/ShopSettingPage/Promocode.svg'
import CrmIcon from '@/assets/svg/ShopSettingPage/Crm.svg'

const pages = {
  order: {
    component: OrderIcon
  },
  language: {
    component: LanguuageIcon
  },
  packing: {
    component: PackingIcon
  },
  warehouse: {
    component: WarehouseIcon
  },
  product: {
    component: ProductIcon
  },
  payment: {
    component: PaymentIcon
  },
  taxinvoice: {
    component: TaxinvoiceIcon
  },
  promocode: {
    component: PromocodeIcon
  },
  crm: {
    component: CrmIcon
  },
}

export default pages