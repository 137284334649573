<template>
  <v-snackbar
    v-model="isSnackbar"
    :color="color || type"
    :timeout="timeout"
    :multi-line="multiLine"
    rounded="pill"
    top>
    <!-- {{ message }} -->
    <div class="d-flex snackbar--container">
      <v-icon
        :color="type"
        left
        small>
        {{ icon }}
      </v-icon>
      <div>
        {{ message }}
      </div>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        icon
        dark
        @click="closeSnackbar()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: undefined
    },
    color: {
      type: String,
      required: false,
      default: undefined
    },
    message: {
      type: String,
      required: true
    },
    multiLine: {
      type: Boolean,
      required: true
    },
    timeout: {
      type: Number,
      required: false,
      default: 3000
    }
  },
  data () {
    return {
      isSnackbar: false
    }
  },
  computed: {
    icon () {
      let icon = 'mdi-information'
      if (this.type === 'success') {
        icon = 'mdi-checkbox-marked-circle'
      } else if (this.type === 'warning') {
        icon = 'mdi-alert-circle'
      } else if (this.type === 'error') {
        icon = 'mdi-alert'
      }
      return icon
    }
  },
  watch: {
    value (e) {
      this.isSnackbar = e
    },
    isSnackbar (e) {
      this.updateSnackbar(e)
    }
  },
  created () {
    this.isSnackbar = this.value
  },
  methods: {
    updateSnackbar (e) {
      this.$emit('input', e)
    },
    closeSnackbar () {
      this.isSnackbar = false
      this.$emit('input', false)
    }
  }
}
</script>
<style scoped>
  .snackbar--container {
    width: 100%;
    text-align: center;
  }
</style>
