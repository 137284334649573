import gShopInfo from '@/assets/svg/SidebarIcon/Grey/ShopInfo.svg'
import gShopSetting from '@/assets/svg/SidebarIcon/Grey/ShopSetting.svg'
import gShipping from '@/assets/svg/SidebarIcon/Grey/Shipping.svg'
import gReceipt from '@/assets/svg/SidebarIcon/Grey/Receipt.svg'
import gMessage from '@/assets/svg/SidebarIcon/Grey/Message.svg'
import gLinkBill from '@/assets/svg/SidebarIcon/Grey/LinkBill.svg'
import gConnector from '@/assets/svg/SidebarIcon/Grey/Connector.svg'
import gSalePage from '@/assets/svg/SidebarIcon/Grey/SalePage2.svg'
import gAgent from '@/assets/svg/SidebarIcon/Grey/Agent.svg'
import gAdministrator from '@/assets/svg/SidebarIcon/Grey/Administrator.svg'
import gAccount from '@/assets/svg/SidebarIcon/Grey/Account.svg'

const grey = {
  g_shopinfo: {
    component: gShopInfo
  },
  g_shopsetting: {
    component: gShopSetting
  },
  g_shipping: {
    component: gShipping
  },
  g_receipt: {
    component: gReceipt
  },
  g_message: {
    component: gMessage
  },
  g_linkbill: {
    component: gLinkBill
  },
  g_connector: {
    component: gConnector
  },
  g_salepage: {
    component: gSalePage
  },
  g_agent: {
    component: gAgent
  },
  g_administrator: {
    component: gAdministrator
  },
  g_account: {
    component: gAccount
  }
}

export default grey
