import gql from 'graphql-tag'

export const GET_SALEPAGES = gql`
  query SalePages ($page: Int, $perPage: Int, $filter: SalePagesSearchFilter, $orderBy: SalePagesSearchOrderBy) {
    salePages (page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
      hasNextPage
      nodes {      
        id
        domain
        customDomain
        customConfig
        published
        publishedAt
        salePageTemplate {
          id
          name
          snapshotUrl
        }
        stock {
          id
          name
          shop {
            cloudflareAuthEmail
            cloudflareAuthKey
          }
        }
      }
    }
  }
`
export const GET_SALEPAGE_DOMAINLIST = gql`
  query SalePage($id: ID!)  {
    salePage(id: $id) {
      id
      stock {
        id
        shop {
          id
          cloudflareAccountList
          cloudflareDomainList 
        }
      }
    }
  }
`

export const GET_SALEPAGE = gql`
  query SalePage($id: ID!) {
    salePage(id: $id) {
      id
      version
      header  
      landing
      product
      products
      cart
      order
      orders
      checkout
      footer
      stylesheet
      headerKeys
      landingKeys
      productsKeys
      productKeys
      cartKeys
      orderKeys
      ordersKeys
      checkoutKeys
      footerKeys
      headerValues
      landingValues
      productValues
      productsValues
      checkoutValues
      cartValues
      orderValues
      ordersValues
      footerValues
      headerSettings
      landingSettings
      productSettings
      productsSettings
      checkoutSettings
      cartSettings
      orderSettings
      ordersSettings
      footerSettings
      customConfig
      stock {
        id
      }
    }
  }
`

export const GET_SALEPAGE_PRODUCTS = gql`
  query ($id: ID!, $page: Int, $perPage: Int, $search: String, $items: [ID!] ) {
  salePage (id: $id) {
    id
    productList(
      page: $page, 
      perPage: $perPage, 
      filter: {
        search: $search, 
        id: $items
      }
    ) {
      nodes {
        id
        name
        inventory
        price
        pictureUrl
      }
      nodesCount
      pagesCount
    }
  }
}
`

export const GET_SALEPAGE_SETPRODUCTS = gql`
query ($id: ID!, $page: Int, $perPage: Int, $search: String, $items: [ID!] ) {
  salePage (id: $id) {
    id
    productSetList(
      page: $page, 
      perPage: $perPage, 
      filter: {
        search: $search, 
        id: $items
      }
    ) {
      nodes {
        id
        name
        price
      }
      nodesCount
      pagesCount
    }
  }
}
`

export const GET_SALEPAGE_BANKACCOUNTS = gql`
  query ($domain: String) {
    salePage (domain: $domain) {
      id
      bankAccountList {
        id
        account
        accountBranch
        accountName
        bank
      }
    }
  }
`

export const GET_SALEPAGE_PROMOCODES = gql`
  query ($domain: String, $search: String) {
    salePage (domain: $domain) {
      promoCodeList(
        filter: {
          search: $search
        }, 
        page: 1, 
        perPage: 999
      ) {
        nodes {
          id
          code
          endDate
          pointUsed
          discountValue
          discountType
          claimable
          minRedeemType
          minRedeemValue
        }
      }
    }
  }
`

export const GET_SALEPAGE_CHECKDOMAIN = gql`
  query SalePageCheckDomain($domain: String!) {
    salePageCheckDomain(domain: $domain)
  }
`

export const GET_SALEPAGE_TRACKING = gql`
  query ($domain: String!, $id: ID!, $orderId: [ID!]) {
    salePage(domain: $domain) {
      id
      findCustomer(id: $id) {
        id
        orderList (
          page: 1, 
          perPage: 999
          filter: {
            id: $orderId
          }
        ) {
          nodes {
            id
            shippingType
            closedNote
            shippingStatus
            shippingLinkClosedNote
          }
        }
      }
    }
  }
`

export const GET_SALEPAGE_SHIPPING = gql`
  query ($domain: String!, $input: SalePageShippingListFilter) {
    salePage (domain: $domain) {
      shippingList(input: $input)
    }
  }
`

export const GET_SALEPAGE_ORDER = gql`
  query ($domain: String!, $id: ID!, $orderId: [ID!]) {
    salePage (domain: $domain) {
      id
      findCustomer (id: $id) {
        orderList (
          page: 1, 
          perPage: 999
          filter: {
            id: $orderId
          }
        ) {
          nodes {
            id
            price
            address
            promoCodeDiscount
            shippingType
            shippingFee
            createdAt
            transferredAt
            printedAt
            packedAt
            closedAt
            closedNote
            markTransferredAt
            paymentRejected
            paymentAcceptedAt
            paidAmount
            orderBank
            orderBankDisplayName
            purchases {
              id
              name
              price
              quantity
              pictureUrl
              stockAvailable
            }
            setPurchases {
              id
              name
              price
              quantity
              pictureUrl
              stockAvailable
            }
          }
        }
      }
    }
  }
`

export const GET_SALEPAGE_ORDERS = gql`
  query ($domain: String!, $id: ID!, $status: CustomerOrderStageFilterEnum) {
    salePage (domain: $domain) {
      id
      findCustomer (id: $id) {
        orderList (
          page: 1, 
          perPage: 999
          filter: {
            status: $status
          }
        ) {
          nodes {
            id
            price
            closed
            taxable
            shippop
            printed
            paymentAccepted
            paymentRejected
            cancelled
            closed
            packed
            paidAmount
            expirationDate
            invoiceNumber
            purchases {
              id
              name
              price
              quantity
              pictureUrl
              stockAvailable
            }
            setPurchases {
              id
              name
              price
              quantity
              pictureUrl
              stockAvailable
            }
          }
        }
      }
    }
  }
`

export const GET_SALEPAGE_CUSTOMER = gql`
  query ($domain: String, $tel: String!, $postcode: String!) {
    salePage (domain: $domain) {
      findCustomer (tel: $tel, postcode: $postcode) {
        id
        addressList
        address,
        name
        tel
        taxId
        email
      }
    }
  } 
`

export default {
  GET_SALEPAGE,
  GET_SALEPAGES,
  GET_SALEPAGE_PRODUCTS,
  GET_SALEPAGE_SETPRODUCTS,
  GET_SALEPAGE_CHECKDOMAIN,
  GET_SALEPAGE_BANKACCOUNTS,
  GET_SALEPAGE_PROMOCODES,
  GET_SALEPAGE_TRACKING,
  GET_SALEPAGE_SHIPPING,
  GET_SALEPAGE_ORDERS,
  GET_SALEPAGE_ORDER,
  GET_SALEPAGE_CUSTOMER,
  GET_SALEPAGE_DOMAINLIST
}
