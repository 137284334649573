import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router/index'
import store from '@/store'
import vuetify from '@/plugins/Vuetify'
import i18n from '@/plugins/VueI18n'
import apolloProvider from '@/plugins/VueApollo'

import '@/plugins/Day'
import '@/plugins/lodash'
import '@/plugins/GlobalComponents'
import '@/plugins/VueClipboard'
import '@/plugins/VueUpload'
import '@/plugins/VueDraggableResizable'
import '@/plugins/VueInfiniteLoading'

Vue.config.productionTip = false

const createApp = () => new Vue({
  router,
  store,
  i18n,
  vuetify,
  apolloProvider,
  render: (h) => h(App)
}).$mount('#app')

createApp()
