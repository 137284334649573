import gql from 'graphql-tag'

export const BUY_CREDIT = gql`
  mutation BuyCredit ($input: BuyCreditInput!) {
    buyCredit (input: $input) {
      paymentRequest {
        id
      }
      errors
    }
  }
`

export const BUY_PLAN = gql`
  mutation BuyPlan ($input: BuyPlanInput!) {
    buyPlan (input: $input) {
      paymentRequest {
        id
      }
      errors
    }
  }
`

export default {
  BUY_CREDIT,
  BUY_PLAN
}
