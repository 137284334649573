var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          attrs: {
            value: _vm.value,
            items: _vm.items,
            "item-value": _vm.itemValue,
            "item-text": _vm.itemText,
            dense: "",
            "hide-details": "",
            outlined: "",
            multiple: ""
          },
          scopedSlots: _vm._u([
            {
              key: "prepend-item",
              fn: function() {
                return [
                  _c(
                    "v-list-item",
                    {
                      attrs: { ripple: "" },
                      on: {
                        click: function($event) {
                          return _vm.toggle()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color:
                                  _vm.value.length > 0 ? "indigo darken-4" : ""
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.labelSelectAll))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mt-2" })
                ]
              },
              proxy: true
            },
            {
              key: "selection",
              fn: function(ref) {
                var item = ref.item
                var index = ref.index
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "div",
                                  _vm._g(_vm._b({}, "div", attrs, false), on),
                                  [
                                    index === 0
                                      ? _c("span", { staticClass: "mr-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              item[_vm.itemText] ||
                                                _vm.itemText(item)
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    index === 1
                                      ? _c(
                                          "span",
                                          { staticClass: "grey--text caption" },
                                          [
                                            _vm._v(
                                              " (+" +
                                                _vm._s(_vm.value.length - 1) +
                                                " others) "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    _vm._l(_vm.value, function(select, i) {
                      return _c("div", { key: i }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              select[_vm.itemText] || _vm.itemText(select)
                            ) +
                            " "
                        )
                      ])
                    }),
                    0
                  )
                ]
              }
            }
          ])
        },
        "v-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }