import Vue from 'vue'
import _ from 'lodash'

const defaultState = {
  byId: {
    create: {
      id: 'create',
      name: 'Invoice',
      size: 'a4',
      condition: 'all',
      width: 794,
      height: 1123,
      innerWidth: 620,
      innerHeight: 850,
      cursor: null,
      histories: [[]],
      canvas: [],
      draftId: null,
      dirty: false
    }
  },
  allIds: ['create']
}

const mutations = {
  ADD (state, payload) {
    const { id } = payload
    Vue.set(state.byId, id, {
      ...defaultState.byId.create,
      ...payload
    })
    state.allIds = Object.keys(state.byId)
  },
  UPDATE (state, payload) {
    const { id } = payload
    Vue.set(state.byId, id, {
      ...defaultState.byId.create,
      ...state.byId[id],
      ...payload
    })
    state.allIds = Object.keys(state.byId)
  }
}

const actions = {
  add ({ commit }, payload) {
    // const { id } = payload
    // const histories = state.byId[id].histories
    commit('ADD', {
      ...defaultState.byId.create,
      ...payload,
      histories: [payload.canvas],
      dirty: false
    })
  },
  update ({ commit }, payload) {
    commit('UPDATE', {
      ...payload,
      dirty: true
    })
  },
  updateAllCanvas ({ commit, state }, payload) {
    const { id, data, keep = true } = payload
    const byId = state.byId[id]
    const histories = byId.cursor !== null ? [] : byId.histories
    if (keep) {
      commit('UPDATE', {
        id,
        canvas: data,
        cursor: null,
        histories: _.take([payload.data, ...histories], 8),
        dirty: true
      })
    } else {
      commit('UPDATE', {
        id,
        canvas: data,
        cursor: null,
        dirty: true
      })
    }
  },
  addCanvas ({ commit, state }, payload) {
    const { id, data } = payload
    const byId = state.byId[id]
    const canvas = [
      ...byId.canvas,
      data
    ]
    const histories = byId.cursor !== null ? [] : byId.histories
    commit('UPDATE', {
      id,
      canvas,
      cursor: null,
      histories: [canvas, ...histories],
      dirty: true
    })
  },
  markAsPristine ({ commit, state }, payload) {
    const { id } = payload
    const byId = state.byId[id]
    commit('UPDATE', {
      id,
      cursor: null,
      histories: _.take([byId.canvas], 8),
      dirty: false
    })
  },
  undoCanvas ({ commit, state }, payload) {
    const { id } = payload
    const byId = state.byId[id]
    if (byId?.histories.length) {
      const nextCursor = byId.cursor !== null ? byId.cursor + 1 : 1
      const history = byId.histories[nextCursor]
      if (history) {
        commit('UPDATE', {
          ...defaultState.byId.create,
          ...byId,
          cursor: nextCursor,
          canvas: history,
          dirty: true
        })
      }
    }
  },
  redoCanvas ({ commit, state }, payload) {
    const { id } = payload
    const byId = state.byId[id]
    if (byId?.histories.length && byId.cursor) {
      const nextCursor = byId.cursor - 1
      const history = byId.histories[nextCursor]
      if (history) {
        commit('UPDATE', {
          ...defaultState.byId.create,
          ...byId,
          cursor: nextCursor,
          canvas: history,
          dirty: true
        })
      }
    }
  }
}

const getters = {
  find: (state) => (id) => _.cloneDeep(state.byId[id]),
  list: (state) => state.allIds.map((id) => _.cloneDeep(state.byId[id]))
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
