import gql from 'graphql-tag'

export const GET_PRODUCTS = gql`
  query ($q: String, $perPage: Int) {
    products (
      perPage: $perPage,
      filter: { 
        search: $q 
      }
    ){
      nodes {
        id
        name
      }
    }
  }
`

export default {
  GET_PRODUCTS
}
