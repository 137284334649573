import gql from 'graphql-tag'

export const CREATE_PAPER = gql`
  mutation CreatePaper ($input: CreatePaperInput!) {
    createPaper (input: $input) {
      paper {
        id
        name
      },
      errors
    }
  }
`

export const UPDATE_PAPER = gql`
  mutation UpdatePaper ($input: UpdatePaperInput!) {
    updatePaper (input: $input) {
      paper {
        id
        name
      },
      errors
    }
  }
`

export const DELETE_PAPER = gql`
  mutation DeletePaper ($input: DeletePaperInput!) {
    deletePaper (input: $input) {
      clientMutationId
    }
  }
`

export const PUBLUSH_PAPER = gql`
  mutation PublishPaper ($input: PublishPaperInput!) {
    publishPaper(input: $input) {
      paper {
        id
      }
    }
  }

`

export default {
  CREATE_PAPER,
  UPDATE_PAPER,
  DELETE_PAPER,
  PUBLUSH_PAPER
}
