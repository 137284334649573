import PlusButton from '@/assets/svg/IconButton/PlusButton.svg'
import CreateButton from '@/assets/svg/IconButton/CreateButton.svg'
import SaveButton from '@/assets/svg/IconButton/SaveButton.svg'

const button = {
  plus: {
    component: PlusButton
  },
  create: {
    component: CreateButton
  },
  save: {
    component: SaveButton
  }
}

export default button
