var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          attrs: {
            color: _vm.color,
            depressed: "",
            rounded: "",
            disabled: _vm.loading || _vm.$attrs.disabled
          }
        },
        "v-btn",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.loading
        ? _c("v-progress-circular", {
            staticClass: "mr-2",
            attrs: { size: "16", width: "2", indeterminate: "" }
          })
        : _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
            _vm._v(" " + _vm._s(_vm.icon) + " ")
          ]),
      _vm._v(" " + _vm._s(_vm.label) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }