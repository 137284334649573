import gql from 'graphql-tag'

export const CREATE_ROLE = gql`
  mutation CreateRole ($input: CreateRoleInput!) {
    createRole (input: $input) {
      role {
        id
        name
      },
      errors
    }
  }
`

export const UPDATE_ROLE = gql`
  mutation UpdateRole ($input: UpdateRoleInput!) {
    updateRole (input: $input) {
      role {
        id
        name
      },
      errors
    }
  }
`

export const DELETE_ROLE = gql`
  mutation DeleteRole ($input: DeleteRoleInput!) {
    deleteRole (input: $input) {
      clientMutationId
    }
  }
`
export default {
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE
}
