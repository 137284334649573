import gql from 'graphql-tag'

export const CREATE_LAZADAPRODUCT = gql`
  mutation LazadaImportProduct ($input: LazadaImportProductInput!) {
    lazadaImportProduct (input: $input) {
      stock {
        id
      },
      errors
    }
  }
`
export default {
  CREATE_LAZADAPRODUCT
}
