import gql from 'graphql-tag'

export const CREATE_STOCK = gql`
  mutation CreateStock ($input: CreateStockInput!) {
    createStock (input: $input) {
      stock {
        id
      }
      errors
    }
  }
`

export const CREATE_STOCKPRODUCT = gql`
  mutation CreateStockProduct ($input: CreateStockProductInput!) {
    createStockProduct (input: $input) {
      stockProduct {
        id
      }
      errors
    }
  }
`

export const IMPORT_STOCKPRODUCT = gql`
  mutation StockImportAllProduct ($input: StockImportAllProductInput!) {
    stockImportAllProduct (input: $input) {
      stock {
        id
      }
    }
  }
`

export const SHOPEE_FETCHORDER = gql`
  mutation ShopeeFetchOrder ($input: ShopeeFetchOrderInput!) {
    shopeeFetchOrder (input: $input) {
      stock {
        id
      }
    }
  }
`

export const LAZADA_FETCHORDER = gql`
  mutation LazadaFetchOrder ($input: LazadaFetchOrderInput!) {
    lazadaFetchOrder (input: $input) {
      stock {
        id
      }
    }
  }
`

export const UPDATE_STOCK = gql`
  mutation UpdateStock ($input: UpdateStockInput!) {
    updateStock (input: $input) {
      stock {
        id
      }
      errors
    }
  }
`

export const DELETE_STOCK = gql`
  mutation DeleteStock ($input: DeleteStockInput!) {
    deleteStock (input: $input) {
      clientMutationId
    }
  }
`

export const DELETE_STOCKPRODUCT = gql`
  mutation DeleteStockProduct ($input: DeleteStockProductInput!) {
    deleteStockProduct (input: $input) {
      clientMutationId
    }
  }
`

export const SHOPEE_LOGOUT = gql`
  mutation ShopeeLogout ($input: ShopeeLogoutInput!) {
    shopeeLogout (input: $input) {
      stock {
        id
      },
      errors
    }
  }
`

export const LAZADA_LOGOUT = gql`
  mutation LazadaLogout ($input: LazadaLogoutInput!) {
    lazadaLogout (input: $input) {
      stock {
        id
      },
      errors
    }
  }
`

export default {
  CREATE_STOCK,
  CREATE_STOCKPRODUCT,
  IMPORT_STOCKPRODUCT,
  SHOPEE_FETCHORDER,
  LAZADA_FETCHORDER,
  UPDATE_STOCK,
  DELETE_STOCK,
  DELETE_STOCKPRODUCT,
  SHOPEE_LOGOUT,
  LAZADA_LOGOUT
}
