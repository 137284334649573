<template>
  <div class="d-flex title secondary--text mb-4">
    <v-icon
      v-if="prependIcon"
      class="mt-2 mr-1"
      :size="prependIconSize">
      {{ prependIcon }}
    </v-icon>
    <span> {{ label }} </span>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    prependIcon: {
      type: String,
      default: () => null
    },
    prependIconSize: {
      type: Number,
      default: 24
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
