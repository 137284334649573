import gql from 'graphql-tag'

export const GET_SALEPAGETEMPLATECATEGORY_ENUM = gql`
  query {
    __type(name: "SalePageTemplateCategoryEnum") {
      enumValues {
        description
        name
      }
    }
  }
`
export const GET_ROLEACCESSSCOPE_ENUM = gql`
  query {
    __type(name: "RoleAccessScopeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_CURRENTCY_ENUM = gql`
  query {
    __type(name: "CurrencyEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_DOCLANGUAGE_ENUM = gql`
  query {
    __type(name: "DocLanguageEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_INVOICEIMAGESIZE_ENUM = gql`
  query {
    __type(name: "InvoiceImageSizeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_INVOICEFONT_ENUM = gql`
  query {
    __type(name: "InvoiceFont") {
      enumValues {
        description
        name
      }
    }
  }
`
export const GET_SHIPPOPLABELSIZE_ENUM = gql`
  query {
    __type(name: "ShippopLabelSizeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`
export const GET_SKYBOXLABELSIZE_ENUM = gql`
  query {
    __type(name: "SkyboxLabelSizeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_INVOICENUMBEROPTION_ENUM = gql`
  query {
    __type(name: "InvoiceNumberOption") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_UILANGUAGE_ENUM = gql`
  query {
    __type(name: "UiLanguageEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_PRODUCTSORTBY_ENUM = gql`
  query {
    __type(name: "ProductSortByOption") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_VATRUNNINGSCOPE_ENUM = gql`
  query {
    __type(name: "VatRunningScopeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_ORDERDISCOUNTTYPE_ENUM = gql`
  query {
    __type(name: "OrderDiscountTypeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_MARKETPLACE_ENUM = gql`
  query {
    __type(name: "MarketplaceEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_PROMOCODEDISCOUNTTYPE_ENUM = gql`
  query {
    __type(name: "PromoCodeDiscountTypeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_PROMOCODEMINREDEEMTYPE_ENUM = gql`
  query {
    __type(name: "PromoCodeMinRedeemTypeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_TRACKINGOPTION_ENUM = gql`
  query {
    __type(name: "TrackingOption") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_ORDERQUERYDATETYPE_ENUM = gql`
  query {
    __type(name: "OrderQueryDateTypeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_PROMOCODECLAIMABLEFILTER_ENUM = gql`
  query {
    __type(name: "PromoCodeClaimableFilterEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_PROMOCODEACTIVEFILTER_ENUM = gql`
  query {
    __type(name: "PromoCodeActiveFilterEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_NORMALINVOICENUMBEROPTION_ENUM = gql`
  query {
    __type(name: "NormalInvoiceNumberOptionEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_PAPER_ENUM = gql`
  query {
    paperEnums
  }
`

export const GET_PAPERSIZE_ENUM = gql`
  query {
    __type(name: "PaperPaperSizeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_PAPERCONDITION_ENUM = gql`
  query {
    __type(name: "PaperConditionEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export default {
  GET_ROLEACCESSSCOPE_ENUM,
  GET_CURRENTCY_ENUM,
  GET_DOCLANGUAGE_ENUM,
  GET_INVOICEIMAGESIZE_ENUM,
  GET_INVOICEFONT_ENUM,
  GET_INVOICENUMBEROPTION_ENUM,
  GET_SHIPPOPLABELSIZE_ENUM,
  GET_SKYBOXLABELSIZE_ENUM,
  GET_UILANGUAGE_ENUM,
  GET_VATRUNNINGSCOPE_ENUM,
  GET_ORDERDISCOUNTTYPE_ENUM,
  GET_MARKETPLACE_ENUM,
  GET_PROMOCODEDISCOUNTTYPE_ENUM,
  GET_PROMOCODEMINREDEEMTYPE_ENUM,
  GET_ORDERQUERYDATETYPE_ENUM,
  GET_PRODUCTSORTBY_ENUM,
  GET_TRACKINGOPTION_ENUM,
  GET_PROMOCODECLAIMABLEFILTER_ENUM,
  GET_PROMOCODEACTIVEFILTER_ENUM,
  GET_NORMALINVOICENUMBEROPTION_ENUM,
  GET_PAPER_ENUM,
  GET_PAPERSIZE_ENUM,
  GET_PAPERCONDITION_ENUM
}
