import _ from 'lodash'

function importAll (r, prefix = '') {
  const components = {}
  r.keys().forEach((key) => {
    const name = _.snakeCase(_.words(_.split(key, '.svg')[0]))
    components[`${prefix}_${name}`] = {
      component: r(key)
    }
  })
  return components
}

export default importAll(
  require.context('@/assets/svg/Paper/elements/', true, /\.svg$/),
  'paper'
)
