<template>
  <div class="d-flex secondary--text text-subtitle-1 mt-1 mb-2">
    <v-icon
      v-if="prependIcon"
      v-bind="$attrs"
      class="mr-2"
      small>
      {{ prependIcon }}
    </v-icon>
    <span v-bind="$attrs"> {{ label }} </span>
    <span
      v-if="required"
      class="error--text ml-1">*</span>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: () => false
    },
    prependIcon: {
      type: String,
      default: () => null
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
