var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", scrollable: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _vm._t(
                  "btn",
                  [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "nav-item nav-item-link nav-icon",
                            attrs: { text: "" }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_c("v-icon", [_vm._v("mdi-help")])],
                      1
                    )
                  ],
                  { attrs: attrs, on: on }
                )
              ]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "app-card pt-6", attrs: { tile: "" } },
        [
          _c(
            "div",
            { staticClass: "close-popup" },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-title", { staticClass: "headline font-weight-regular" }, [
            _vm._v(" สอบถามได้ที่ ")
          ]),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("p", [_vm._v(" โทรศัพท์ ")]),
                _c("h2", [_vm._v(" 065 226 8844 ")]),
                _c("br"),
                _c("p", { staticClass: "no-margin large-text" }, [
                  _vm._v(" Facebook ")
                ]),
                _c("h2", [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://www.facebook.com/shipnity"
                      }
                    },
                    [_vm._v("facebook.com/shipnity")]
                  )
                ]),
                _c("br"),
                _c("p", [
                  _vm._v(" Line "),
                  _c("span", { staticClass: "text-primary" }, [
                    _vm._v("@shipnity")
                  ])
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      href: "http://line.me/ti/p/%40shipnity",
                      target: "_blank",
                      color: "#05B305",
                      dark: ""
                    }
                  },
                  [_vm._v(" Add Friend ")]
                ),
                _c("br"),
                _c("br"),
                _c("section", [
                  _c("h4", { staticClass: "title custom-font text-black" }, [
                    _vm._v(" เวลาทำการ ")
                  ]),
                  _c("p", [
                    _vm._v(" จันทร์ - ศุกร์ 9.00 - 18.00 น."),
                    _c("br"),
                    _vm._v(" เสาร์ - อาทิตย์ 10.00 - 16.00 น."),
                    _c("br")
                  ])
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }