import gql from 'graphql-tag'

export const GET_SHOPEEPRODUCTS = gql`
  query ($stockId: ID! ,$page: Int, $perPage: Int) {
    shopeeProducts (
      stockId: $stockId,
      page: $page,
      perPage: $perPage
    ) {
      code
      name
      price
      total
    }
  }
`

export default {
  GET_SHOPEEPRODUCTS
}
