import gql from 'graphql-tag'

export const CREATE_SHIPPING_CALCULATOR = gql`
  mutation CreateShippingCalculator ($input: CreateShippingCalculatorInput!) {
    createShippingCalculator (input: $input) {
      shippingCalculator {
        id
      }
      errors
    }
  }
`

export const CREATE_SHIPPING_CALCULATION = gql`
  mutation CreateShippingCalculation ($input: CreateShippingCalculationInput!) {
    createShippingCalculation (input: $input) {
      shippingCalculation {
        id
      }
      errors
    }
  }
`

export const UPDATE_SHIPPING_CALCULATOR = gql`
  mutation UpdateShippingCalculator ($input: UpdateShippingCalculatorInput!) {
    updateShippingCalculator (input: $input) {
      shippingCalculator {
        id
      }
      errors
    }
  }
`

export const UPDATE_SHIPPING_CALCULATION = gql`
  mutation UpdateShippingCalculation ($input: UpdateShippingCalculationInput!) {
    updateShippingCalculation (input: $input) {
      shippingCalculation {
        id
      }
      errors
    }
  }
`

export const DELETE_SHIPPING_CALCULATOR = gql`
  mutation DeleteShippingCalculator ($input: DeleteShippingCalculatorInput!) {
    deleteShippingCalculator (input: $input) {
      clientMutationId
    }
  }
`

export const DELETE_SHIPPING_CALCULATION = gql`
  mutation DeleteShippingCalculation ($input: DeleteShippingCalculationInput!) {
    deleteShippingCalculation (input: $input) {
      clientMutationId
    }
  }
`

export default {
  CREATE_SHIPPING_CALCULATOR,
  CREATE_SHIPPING_CALCULATION,
  UPDATE_SHIPPING_CALCULATOR,
  UPDATE_SHIPPING_CALCULATION,
  DELETE_SHIPPING_CALCULATOR,
  DELETE_SHIPPING_CALCULATION
}
