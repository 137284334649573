var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { id: "app-sidebar", nav: "" } },
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-icon",
            [_c("v-icon", [_vm._v(" mdi-cog-outline ")])],
            1
          ),
          _c("v-list-item-content", [
            _c("div", { staticClass: "title secondary--text" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("app.header")))])
            ])
          ]),
          _c("v-list-item-icon", [_c("spotlight")], 1)
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "px-3" },
        [
          _vm._l(_vm.sideBarMenu, function(item) {
            return [
              item.show
                ? _c(
                    "v-list-item",
                    {
                      key: item.title,
                      class: {
                        "v-list-item--active": _vm.currentPage === item.name
                      },
                      attrs: { to: item.to, link: "" },
                      on: {
                        click: function($event) {
                          return _vm.goToPage(item.name)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _vm.currentPage === item.name
                            ? _c("v-icon", [
                                _vm._v(" " + _vm._s(item.icon.white) + " ")
                              ])
                            : _c("v-icon", [
                                _vm._v(" " + _vm._s(item.icon.grey) + " ")
                              ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "secondary--text pt-1" },
                        [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }