import gql from 'graphql-tag'

export const CREATE_RESELLER = gql`
  mutation CreateReseller ($input: CreateResellerInput!) {
    createReseller (input: $input) {
      reseller {
        id
      },
      errors
    }
  }
`

export const CREATE_RESELLER_USER = gql`
  mutation CreateUserReseller ($input: CreateUserResellerInput!) {
    createUserReseller (input: $input) {
      reseller {
        id
      },
      errors
    }
  }
`

export const UPDATE_RESELLER = gql`
  mutation UpdateReseller ($input: UpdateResellerInput!) {
    updateReseller (input: $input) {
      reseller {
        id
        resellerCode
        pictureUrl
        name
        tier {
          id
        }
        tel
        lineId
        facebook
        instagram
        resellerAddress
        taxId
        users {
          email
          shopType
        }
      },
      errors
    }
  }
`

export const DELETE_RESELLER = gql`
  mutation DeleteReseller ($input: DeleteResellerInput!) {
    deleteReseller (input: $input) {
      clientMutationId
    }
  }
`

export const DELETE_RESELLER_USER = gql`
  mutation DeleteUserReseller ($input: DeleteUserResellerInput!) {
    deleteUserReseller (input: $input) {
      clientMutationId
    }
  }
`

export default {
  CREATE_RESELLER,
  CREATE_RESELLER_USER,
  UPDATE_RESELLER,
  DELETE_RESELLER,
  DELETE_RESELLER_USER
}
