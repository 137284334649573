import HttpRequest from './HttpRequest'

class AuthProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_RESTAPI)
  }

  testCsrfToken = (payload, config) => this.post(
    `/test_csrf_token/${payload}`,
    config
  ).catch((err) => console.log('test csrf error', err))

  getCsrfToken = (payload, config) => this.get('/get_csrf_token', { payload }, config)
    .catch((err) => this.handleError(err))

  logout = (payload, config) => this.delete2(
    '/users/sign_out',
    { payload },
    config
  )
}

export default new AuthProvider()
