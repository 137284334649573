import Vue from 'vue'
import VueRouter from 'vue-router'
import IfAuthenticated, { hasPermission } from '@/router/guards/auth.guard'
import ShopSettingRoutes from '@/router/routes/shop-setting'
import PageErrorRoutes from '@/router/routes/page-error'
import store from '@/store'
import { GET_ME, GET_SETTING_USER } from '@/resources/graphql'
import { apolloClient } from '@/plugins/VueApollo'
import { i18nLoader } from '@/plugins/VueI18n'
import AuthProvider from '@/resources/AuthProvider'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ShopInfo',
    component: () => import('@/views/ShopInfo/ShopInfo.vue'),
    beforeEnter: IfAuthenticated,
    meta: {
      title: '',
      desc: '',
      keywords: [],
      breadCrumb: 'shopinfo'
    }
  },
  ShopSettingRoutes,
  {
    path: '/shipping',
    name: 'Shipping',
    component: () => import('@/views/Shipping/Shipping.vue'),
    beforeRouteEnter: IfAuthenticated,
    meta: { breadCrumb: 'shipping' }
  },
  // {
  //   path: '/paper-builder',
  //   name: 'PaperBuilder',
  //   component: () => import('@/views/PaperBuilder/PaperBuilder.vue'),
  //   beforeEnter: (to, from, next) => hasPermission(next, 'settingCustomPrint'),
  //   meta: { breadCrumb: 'receipt' }
  // },
  {
    path: '/receipt',
    name: 'Receipt',
    component: () => import('@/views/Receipt/Receipt.vue'),
    beforeEnter: (to, from, next) => hasPermission(next, 'settingCustomPrint'),
    meta: { breadCrumb: 'receipt' }
  },
  {
    path: '/papers',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    meta: { breadCrumb: 'papers' },
    children: [
      {
        path: '',
        name: 'Papers',
        component: () => import('@/views/Papers/Papers.vue')
      },
      {
        path: ':id',
        name: 'Paper',
        component: () => import('@/views/Papers/pages/Paper/Paper.vue'),
        beforeEnter: (to, from, next) => hasPermission(next, 'settingCustomPrint'),
        meta: { breadCrumb: 'papers' }
      }
    ]
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import('@/views/Message/Message.vue'),
    beforeEnter: (to, from, next) => hasPermission(next, 'settingManageMessage'),
    meta: { breadCrumb: 'message' }
  },
  {
    path: '/link-bill',
    name: 'LinkBill',
    component: () => import('@/views/LinkBill/LinkBill.vue'),
    beforeEnter: IfAuthenticated,
    meta: { breadCrumb: 'linkbill' }
  },
  {
    path: '/connector',
    name: 'Connector',
    component: () => import('@/views/Connector/Connector.vue'),
    beforeEnter: IfAuthenticated,
    meta: { breadCrumb: 'connector' }
  },
  {
    path: '/sale-page',
    name: 'SalePage',
    component: () => import('@/views/SalePage/SalePage.vue'),
    beforeEnter: IfAuthenticated,
    meta: { breadCrumb: 'salepage' }
  },
  {
    path: '/agent',
    name: 'Agent',
    component: () => import('@/views/Agent/Agent.vue'),
    beforeEnter: (to, from, next) => hasPermission(next, 'settingReseller'),
    meta: { breadCrumb: 'agent' }
  },
  {
    path: '/administrator',
    name: 'Administrator',
    component: () => import('@/views/Administrator/Administrator.vue'),
    beforeEnter: (to, from, next) => hasPermission(next, 'settingManageUser'),
    meta: { breadCrumb: 'administrator' }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account/Account.vue'),
    beforeEnter: (to, from, next) => hasPermission(next, 'changePassword'),
    meta: { breadCrumb: 'account' }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search/Search.vue'),
    beforeEnter: IfAuthenticated,
    meta: { breadCrumb: 'search' }
  },
  PageErrorRoutes,
  {
    path: '*',
    name: 'NotFoundComponent',
    meta: { breadCrumb: 'pageerror' },
    component: () => import('@/views/PageError/PageError.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTE_PREFIX,
  routes
})

router.beforeEach(async (to, from, next) => {
  const res = await AuthProvider.getCsrfToken()
  store.dispatch('Auth/setCsrf', res.token)

  const { data: { me: { id, email, role } } } = await apolloClient.query({
    query: GET_ME
  }).catch(() => {
    window.location.href = process.env.VUE_APP_SHIPNITY
  })
  if (!role.accessSetting) {
    window.location.href = process.env.VUE_APP_SHIPNITY
  }
  const { data: { setting: { name } } } = await apolloClient.query({
    query: GET_SETTING_USER
  })
  i18nLoader('th').then(() => {
    store.dispatch('Auth/setUserId', Number(id))
    store.dispatch('Auth/setPermission', role)
    store.dispatch('Setting/setShopName', name)
    store.dispatch('Setting/setEmail', email)
    next()
  })
})

export default router
