const state = {
  csrf: null,
  userId: null,
  permission: {}
}

const mutations = {
  SET_USER_ID (state, payload) {
    state.userId = payload
  },
  SET_CSRF (state, payload) {
    state.csrf = payload
  },
  SET_PERMISSION (state, payload) {
    state.permission = payload
  }
}

const actions = {
  setUserId ({ commit }, payload) {
    commit('SET_USER_ID', payload)
  },
  setCsrf ({ commit }, payload) {
    commit('SET_CSRF', payload)
  },
  setPermission ({ commit }, payload) {
    commit('SET_PERMISSION', payload)
  }
}

const getters = {
  userId: (state) => state.userId,
  csrf: (state) => state.csrf,
  permission: (state) => state.permission
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
