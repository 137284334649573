import gql from 'graphql-tag'

export const GET_TIERS = gql`
  query ($page: Int, $perPage: Int) {
    tiers (page: $page, perPage: $perPage) {
      nodes {
        id
        name,
        resellerCount
        stock {
          name
        }
        tierProducts {
          nodesCount
        }
      }
      nodesCount
      pagesCount
    }
  }
`

export default {
  GET_TIERS
}
