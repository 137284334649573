import gql from 'graphql-tag'

export const CREATE_BANK_ACCOUNT = gql`
  mutation CreateBankAccount ($input: CreateBankAccountInput!) {
    createBankAccount (input: $input) {
      bankAccount {
        id
      }
      errors
    }
  }
`

export const UPDATE_BANK_ACCOUNT = gql`
  mutation UpdateBankAccount ($input: UpdateBankAccountInput!) {
    updateBankAccount (input: $input) {
      bankAccount {
        id
      }
      errors
    }
  }
`

export const DELETE_BANK_ACCOUNT = gql`
  mutation DeleteBankAccount ($input: DeleteBankAccountInput!) {
    deleteBankAccount (input: $input) {
      clientMutationId
    }
  }
`

export default {
  CREATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT
}
