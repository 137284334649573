import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import SvgIcon from '@/plugins/SvgIcon/index'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: SvgIcon
  },
  theme: {
    light: true,
    dark: false,
    themes: {
      light: {
        primary: '#1BA7E1',
        error: '#DF6C4F',
        success: '#00AD9B',
        warning: '#F9BA46',
        secondary: '#6C6C6C'
      },
      dark: {
        primary: '#1BA7E1',
        error: '#DF6C4F',
        success: '#00AD9B',
        warning: '#F9BA46',
        secondary: '#FFFFFF'
      }
    },
    options: {
      customProperties: true
    }
  }
})
