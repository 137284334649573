import gql from 'graphql-tag'

export const GET_PAPERFIELDS = gql`
  query ($tableName: PaperTableEnum!){
    paperFields(tableName: $tableName)
  }
`

export default {
  GET_PAPERFIELDS
}
