<template>
  <v-menu
    class="nav-dropdown"
    :min-width="150"
    tile
    offset-y>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="nav-item nav-item-brand"
        text
        v-bind="attrs"
        v-on="on">
        {{ domainNameOnly }}
      </v-btn>
    </template>
    <v-list class="nav-list-dropdown">
      <v-list-item
        :href="`https://www.${domainName}/orders/new`"
        link>
        <v-list-item-title>
          + Order
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :href="`https://www.${domainName}/expenses/new`"
        link>
        <v-list-item-title>
          + ค่าใช้จ่าย
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  computed: {
    domainNameOnly () {
      const host = window.location.hostname
      const parts = host.split('.')
      return parts.length === 3 ? `${parts[1]}` : parts[0]
    },
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    }
  }
}
</script>
