var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c("text-field-label", { attrs: { label: _vm.text } }),
      _c("v-spacer"),
      _c(
        "div",
        { staticClass: "mr-2 mt-1", class: { "mr-2 mt-2": !_vm.loading } },
        [
          _vm.loading
            ? _c("v-progress-circular", {
                attrs: { size: "16", width: "2", indeterminate: "" }
              })
            : _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c("v-img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.icon && !_vm.isTimeout,
                        expression: "icon && !isTimeout"
                      }
                    ],
                    attrs: {
                      src: _vm.icon,
                      height: "16",
                      eager: "",
                      contain: ""
                    }
                  })
                ],
                1
              )
        ],
        1
      ),
      _c(
        "v-switch",
        _vm._g(
          {
            staticClass: "mt-0 ml-1",
            attrs: {
              "input-value": _vm.value,
              disabled: _vm.loading,
              inset: "",
              "hide-details": ""
            },
            on: {
              change: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }