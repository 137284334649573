<template>
  <v-app-bar
    id="app-bar"
    style="z-index: 10;"
    color="white"
    height="52"
    light
    app
    elevation="0"
    clipped-left>
    <v-container fluid>
      <v-row no-gutters>
        <v-col
          v-if="!$vuetify.breakpoint.mobile"
          cols="12"
          class="d-flex">
          <brand-dropdown />
          <v-btn
            v-for="nav in navbars"
            :key="`navbar_${nav.text}`"
            :href="nav.link"
            class="nav-item nav-item-link"
            text>
            <i
              :class="nav.icon"
              class="mr-1" />
            {{ nav.text }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="isShipnity"
            :href="`https://blog.${domainName}/how-to/`"
            target="_blank"
            class="nav-item nav-item-link"
            text>
            คู่มือ
          </v-btn>
          <help />
          <v-btn
            :href="`https://www.${domainName}/setting/`"
            class="nav-item nav-item-link nav-icon"
            text>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <account-dropdown :min-width="180" />
        </v-col>
        <v-col
          v-else
          class="d-flex justify-space-between">
          <brand-dropdown />
          <v-spacer />
          <v-menu
            class="nav-dropdown"
            min-width="100%"
            tile
            offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-app-bar-nav-icon
                v-bind="attrs"
                v-on="on" />
            </template>
            <v-list
              id="nav-dropdown-mobile"
              class="nav-list-dropdown full-width">
              <v-list-item
                v-for="nav in navbars"
                :key="`navbar_${nav.text}`"
                :href="nav.link"
                class="nav-item nav-item-link"
                text>
                <v-icon
                  v-show="nav.icon"
                  class="mr-1"
                  small>
                  {{ nav.icon }}
                </v-icon>
                {{ nav.text }}
              </v-list-item>
              <v-list-item
                v-if="isShipnity"
                :href="`https://blog.${domainName}/how-to/`"
                target="_blank"
                class="nav-item nav-item-link"
                text>
                คู่มือ
              </v-list-item>
              <help v-if="isShipnity">
                <template v-slot:btn="{ attrs, on }">
                  <v-list-item
                    class="nav-item nav-item-link nav-icon"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>mdi-help</v-icon>
                  </v-list-item>
                </template>
              </help>
              <v-list-item
                :href="`https://www.${domainName}/setting/`"
                target="_blank"
                class="nav-item nav-item-link nav-icon">
                <v-icon>mdi-cog</v-icon>
              </v-list-item>
              <v-list-item>
                <account-dropdown min-width="100%">
                  <template v-slot:btn="{ attrs, on }">
                    <v-btn
                      class="nav-item nav-item-dropdown justify-space-between"
                      tag="a"
                      text
                      block
                      v-bind="attrs"
                      v-on="on">
                      {{ shopName }}
                      <v-spacer />
                      <v-icon small>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                </account-dropdown>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import Help from '@/components/Navbar/Help.vue'
import BrandDropdown from '@/components/Navbar/BrandDropdown.vue'
import AccountDropdown from '@/components/Navbar/AccountDropdown.vue'

export default {
  name: 'Navbar',
  components: {
    Help,
    BrandDropdown,
    AccountDropdown
  },
  data () {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      navbars: [],
      drawer: true
    }
  },
  computed: {
    ...mapGetters({
      loading: 'Notification/isLoading',
      shopName: 'Setting/shopName'
    }),
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    },
    isShipnity () {
      return this.domainName === 'shipnity.com' || this.domainName === 'localhost'
    }
  },
  created () {
    this.navbars = [
      {
        icon: 'icon icon-shop icon-small',
        link: `https://www.${this.domainName}/orders`,
        text: 'ออเดอร์'
      },
      {
        icon: 'icon icon-approve-invoice icon-small',
        link: `https://www.${this.domainName}/products`,
        text: 'สินค้า'
      },
      {
        icon: 'icon icon-analytics icon-small',
        link: `https://www.${this.domainName}/reports/table_report`,
        text: 'รายงาน'
      },
      {
        icon: 'mdi mdi-account-outline',
        link: `https://www.${this.domainName}/contacts`,
        text: 'ผู้ติดต่อ'
      },
      {
        icon: 'icon icon-history icon-small',
        link: `https://www.${this.domainName}/histories/orders`,
        text: 'ประวัติ'
      },
      {
        icon: 'mdi mdi-chat-outline',
        link: `https://www.${this.domainName}/whalebox`,
        text: 'แชท/ไลฟ์'
      }
    ]
  },
  methods: {
    draw () {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style>
  .nav-item.nav-item-dropdown {
    padding: 0 12px !important;
  }
</style>
