import wShopInfo from '@/assets/svg/SidebarIcon/White/ShopInfo.svg'
import wShopSetting from '@/assets/svg/SidebarIcon/White/ShopSetting.svg'
import wShipping from '@/assets/svg/SidebarIcon/White/Shipping.svg'
import wReceipt from '@/assets/svg/SidebarIcon/White/Receipt.svg'
import wMessage from '@/assets/svg/SidebarIcon/White/Message.svg'
import wLinkBill from '@/assets/svg/SidebarIcon/White/LinkBill.svg'
import wConnector from '@/assets/svg/SidebarIcon/White/Connector.svg'
import wSalePage from '@/assets/svg/SidebarIcon/White/SalePage2.svg'
import wAgent from '@/assets/svg/SidebarIcon/White/Agent.svg'
import wAdministrator from '@/assets/svg/SidebarIcon/White/Administrator.svg'
import wAccount from '@/assets/svg/SidebarIcon/White/Account.svg'

const white = {
  w_shopinfo: {
    component: wShopInfo
  },
  w_shopsetting: {
    component: wShopSetting
  },
  w_shipping: {
    component: wShipping
  },
  w_receipt: {
    component: wReceipt
  },
  w_message: {
    component: wMessage
  },
  w_linkbill: {
    component: wLinkBill
  },
  w_connector: {
    component: wConnector
  },
  w_salepage: {
    component: wSalePage
  },
  w_agent: {
    component: wAgent
  },
  w_administrator: {
    component: wAdministrator
  },
  w_account: {
    component: wAccount
  }
}

export default white
