<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="btn"
        :attrs="attrs"
        :on="on">
        <v-btn
          class="nav-item nav-item-link nav-icon"
          v-bind="attrs"
          text
          v-on="on">
          <v-icon>mdi-help</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card
      class="app-card pt-6"
      tile>
      <div class="close-popup">
        <v-btn
          icon
          small
          @click="closeDialog()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-card-title class="headline font-weight-regular">
        สอบถามได้ที่
      </v-card-title>
      <v-card-text>
        <div class="container">
          <p>
            โทรศัพท์
          </p>
          <h2>
            065 226 8844
          </h2>
          <br>
          <p class="no-margin large-text">
            Facebook
          </p>
          <h2>
            <a
              target="_blank"
              href="https://www.facebook.com/shipnity">facebook.com/shipnity</a>
          </h2>
          <br>
          <p>
            Line <span class="text-primary">@shipnity</span>
          </p>
          <v-btn
            href="http://line.me/ti/p/%40shipnity"
            target="_blank"
            class="btn btn-primary"
            color="#05B305"
            dark>
            Add Friend
          </v-btn>
          <br><br>
          <section>
            <h4 class="title custom-font text-black">
              เวลาทำการ
            </h4>
            <p>
              จันทร์ - ศุกร์ 9.00 - 18.00 น.<br>
              เสาร์ - อาทิตย์ 10.00 - 16.00 น.<br>
            </p>
          </section>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>
