export {
  GET_SALEPAGETEMPLATECATEGORY_ENUM,
  GET_ROLEACCESSSCOPE_ENUM,
  GET_CURRENTCY_ENUM,
  GET_DOCLANGUAGE_ENUM,
  GET_INVOICEIMAGESIZE_ENUM,
  GET_INVOICEFONT_ENUM,
  GET_INVOICENUMBEROPTION_ENUM,
  GET_SHIPPOPLABELSIZE_ENUM,
  GET_SKYBOXLABELSIZE_ENUM,
  GET_UILANGUAGE_ENUM,
  GET_VATRUNNINGSCOPE_ENUM,
  GET_ORDERDISCOUNTTYPE_ENUM,
  GET_MARKETPLACE_ENUM,
  GET_PROMOCODEDISCOUNTTYPE_ENUM,
  GET_PROMOCODEMINREDEEMTYPE_ENUM,
  GET_ORDERQUERYDATETYPE_ENUM,
  GET_PROMOCODECLAIMABLEFILTER_ENUM,
  GET_PROMOCODEACTIVEFILTER_ENUM,
  GET_NORMALINVOICENUMBEROPTION_ENUM,
  GET_PAPER_ENUM,
  GET_PAPERSIZE_ENUM,
  GET_PAPERCONDITION_ENUM
} from '@/resources/graphql/query/Enum'
export { GET_ME } from '@/resources/graphql/query/Me'
export { GET_USER } from '@/resources/graphql/query/User'
export { GET_USERS } from '@/resources/graphql/query/Users'
export { GET_ROLE } from '@/resources/graphql/query/Role'
export { GET_ROLES } from '@/resources/graphql/query/Roles'
export { GET_STOCKS } from '@/resources/graphql/query/Stocks'
export { GET_PRODUCTS } from '@/resources/graphql/query/Products'
export { GET_RESELLER } from '@/resources/graphql/query/Reseller'
export { GET_RESELLERS } from '@/resources/graphql/query/Resellers'
export { GET_TIERS } from '@/resources/graphql/query/Tiers'
export { GET_LAZADAPRODUCTS } from '@/resources/graphql/query/LazadaProducts'
export { GET_SHOPEEPRODUCTS } from '@/resources/graphql/query/ShopeeProducts'
export {
  GET_TIER,
  GET_TIER_PRODUCTS
} from '@/resources/graphql/query/Tier'
export {
  GET_SETTING_SALEPAGE,
  GET_SETTING_RECEIPT,
  GET_SETTING_RESELLER,
  GET_SETTING_MESSAGE,
  GET_SETTING_SHOPEE,
  GET_SETTING_SHIPPING,
  GET_SETTING_LAZADA,
  GET_SETTING_WEBSITE,
  GET_SETTING_CLOUNDFLARE,
  GET_SETTING_FACEBOOKPIXEL,
  GET_SETTING_FACEBOOKVERIFYDOMAIN,
  GET_SETTING_GOOGLEANALYSIS,
  GET_SETTING_LINKBILL,
  GET_SETTING_PERMISSION,
  GET_SETTING_USER
} from '@/resources/graphql/query/Setting'
export { GET_PAPERGROUPS } from '@/resources/graphql/query/PaperGroups'
export { GET_PAPERGROUP, GET_PAPERHTML, GET_PAPERGETSIZE } from '@/resources/graphql/query/PaperGroup'
export { GET_PAPERFIELDS } from '@/resources/graphql/query/PaperFields'
export {
  GET_SALEPAGE,
  GET_SALEPAGES,
  GET_SALEPAGE_PRODUCTS,
  GET_SALEPAGE_SETPRODUCTS,
  GET_SALEPAGE_CHECKDOMAIN,
  GET_SALEPAGE_BANKACCOUNTS,
  GET_SALEPAGE_PROMOCODES,
  GET_SALEPAGE_SHIPPING,
  GET_SALEPAGE_TRACKING,
  GET_SALEPAGE_ORDERS,
  GET_SALEPAGE_ORDER,
  GET_SALEPAGE_CUSTOMER,
  GET_SALEPAGE_DOMAINLIST
} from '@/resources/graphql/query/sale-page'

export {
  CREATE_SHIPPING_CALCULATOR,
  CREATE_SHIPPING_CALCULATION,
  UPDATE_SHIPPING_CALCULATOR,
  UPDATE_SHIPPING_CALCULATION,
  DELETE_SHIPPING_CALCULATOR,
  DELETE_SHIPPING_CALCULATION
} from '@/resources/graphql/mutation/ShippingCalculator'
export { CREATE_LAZADAPRODUCT } from '@/resources/graphql/mutation/LazadaProducts'
export { CREATE_SHOPEEPRODUCT } from '@/resources/graphql/mutation/ShopeeProducts'

export { CREATE_UNIT, UPDATE_UNIT, DELETE_UNIT } from '@/resources/graphql/mutation/Unit'
export { CREATE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT, DELETE_BANK_ACCOUNT } from '@/resources/graphql/mutation/BankAccount'
export { CREATE_PROMOCODE, UPDATE_PROMOCODE, DELETE_PROMOCODE } from '@/resources/graphql/mutation/PromoCode'
export { CREATE_USER, UPDATE_USER, DELETE_USER } from '@/resources/graphql/mutation/User'
export { CREATE_ROLE, UPDATE_ROLE, DELETE_ROLE } from '@/resources/graphql/mutation/Role'
export {
  CREATE_RESELLER,
  CREATE_RESELLER_USER,
  UPDATE_RESELLER,
  DELETE_RESELLER,
  DELETE_RESELLER_USER
} from '@/resources/graphql/mutation/Reseller'
export {
  CREATE_STOCK,
  CREATE_STOCKPRODUCT,
  IMPORT_STOCKPRODUCT,
  SHOPEE_FETCHORDER,
  LAZADA_FETCHORDER,
  UPDATE_STOCK,
  DELETE_STOCK,
  DELETE_STOCKPRODUCT,
  SHOPEE_LOGOUT,
  LAZADA_LOGOUT
} from '@/resources/graphql/mutation/Stock'
export {
  CREATE_TIER,
  CREATE_TIER_PRODUCT,
  CREATE_QUANTITY_PRICE_TIER_PRODUCT,
  UPDATE_TIER,
  UPDATE_QUANTITY_PRICE_TIER_PRODUCT,
  IMPORT_TIER_ALLPRODUCT,
  IMPORT_TIER_EXCEL,
  UPDATE_TIER_PRODUCT,
  UPDATE_ALLRESELLERINTIER,
  DELETE_TIER,
  DELETE_TIER_PRODUCT,
  DELETE_QUANTITY_PRICE_TIER_PRODUCT
} from '@/resources/graphql/mutation/Tier'
export { UPDATE_SETTING, SUBMIT_JT_REGISTER } from '@/resources/graphql/mutation/Setting'
export { BUY_CREDIT, BUY_PLAN } from '@/resources/graphql/mutation/Plan'
export {
  CREATE_PAPERGROUP,
  UPDATE_PAPERGROUP,
  DELETE_PAPERGROUP
} from '@/resources/graphql/mutation/PaperGroup'

export {
  CREATE_PAPER,
  UPDATE_PAPER,
  DELETE_PAPER,
  PUBLUSH_PAPER
} from '@/resources/graphql/mutation/Paper'

export {
  CREATE_SALEPAGE,
  UPDATE_SALEPAGE,
  PUBLISH_SALEPAGE,
  UPLOAD_SALEPAGE_IMAGE,
  DELETE_SALEPAGE,
  SELECT_SALEPAGE_CUSTOMDOMAIN,
  DELETE_SALEPAGE_CUSTOMDOMAIN
} from '@/resources/graphql/mutation/sale-page'
