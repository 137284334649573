import gql from 'graphql-tag'

export const GET_PAPERGROUPS = gql`
  query ($after: String, $first: Int) {
    paperGroups (after: $after, first: $first) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        name
        savedCalculations
        canvas
        height
        width
        paperSize
        papers {
          nodes {
            id
            active
          }
        }
      }
    }
  }
`

export default {
  GET_PAPERGROUPS
}
