import gql from 'graphql-tag'

export const GET_SETTING_SHOPINFO = gql`query {
  setting {
    id
    logoUrl
    name
    tel
    address
    taxId
    orderLinkColor
    maxUsers
    maxStocks
    maxResellers
    packageName
    availableAndReservedCredit
    planExpiration
    livePremium
    showAddress
    reportAdvanceExpiredAt
    currentPlan {
      id
      maxResellers
      maxStocks
      maxUsers
      months
      name
      price
      planType
    }
  }
}`

export const GET_SETTING_ORDER = gql`query {
  setting {
    id
    autoTransferBank
    paymentMethods
    autoTransfer
    editProductAfterPay
    enableHolding
    posAutoRedirect
    liveOrderAutoMerge
    changePrint
    pendingAllOrders
    invoiceNumberOption
    preorderLimit
    autoVerifyPayment
    autoVerifyAcceptOverpaid
    autoVerifyAcceptUnderpaid
    remainingAutoVerifyTrial
  }
}`

export const GET_SETTING_LANGUAGE = gql`query {
  setting {
    id
    uiLanguage
    docLanguage
    currency
  }
}`

export const GET_SETTING_PACKING = gql`query {
  setting {
    id
    enablePackedStage
    checkProductBeforeConfirmPack
    orderCheckItemAutoClick
  }
}`

export const GET_SETTING_STOCK = gql`query {
  setting {
    id
    shareInventoryBetweenStock,
    facebookPages
  }
}`

export const GET_SETTING_PRODUCT = gql`query {
  setting {
    id
    productSortBy
    quantityDiscountMethod
  }
}`

export const GET_SETTING_PAYMENT = gql`query {
  setting {
    id
    acceptCreditCard
    customerCreditCardRate
    creditCardTransferBankName
    creditCardTransferBankAccountName
    creditCardTransferBankAccount
    creditCardTransferBankAccountBranch
    paymentMethods
  }
}`

export const GET_SETTING_TAXINVOICE = gql`query {
  setting {
    id
    vatRunningScope,
    defaultTaxInvoice,
    vatExclude
  }
}`

export const GET_SETTING_PROMOCODE = gql`query {
  setting {
    id
    shareInventoryBetweenStock
  }
}`

export const GET_SETTING_CRM = gql`query {
  setting {
    id
    enableCrm
  }
}`

export const GET_SETTING_SHIPPING = gql`
  query {
    setting {
      id
      shippingTypes
      selectableShippingTypes
      defaultShippingType,
      autoDropoff
      jtMemberName
      jtMemberCode
      dhlShippingId
      shipjungToken
      scgMemberCode
      shopurl
      trackingOption
      customerCodRate
      address
    }
  }
`

export const GET_SETTING_RECEIPT = gql`
  query {
    setting {
      id
      invoiceShowTag
      showAnnotation
      invoiceImageSize
      orderBarcode
      invoiceShowBarcode
      taxInvoiceShowBarcode
      invoiceFont
      dropoffShowProductList
      shippopLabelSize
      skyboxLabelSize
      normalInvoiceNumberOption
    }
  }
`

export const GET_SETTING_MESSAGE = gql`
  query {
    setting {
      id
      facebookSendClosedNote
      facebookSendTransfer
      smsSendTransfer
      notifyWhenShopPay
      confirmAddressWhenPay
      notifyWhenUpdateOrder
      notifyClosedNoteWithTrackingLink
      productStockReminder
      smsText
      smsTextDefault
      autoSms
      autoSmsTransfer
      autoEmail
      smsTransferMsg
      smsTransferMsgDefault
      notPaidNotifyMessage
      notPaidNotifyMessageDefault
      productStockMsg
      productStockMsgDefault
      orderLinkText
      orderLinkTextDefault
      orderLinkTextShowProduct
      hideOrderSummaryTextInSummaryText
      orderSummaryText
      codSummaryText
    }
  }
`

export const GET_SETTING_LINKBILL = gql`
  query {
    setting {
      id
      useCustomerLink
      whaleboxBillingDomain
      customerLinkRequireSms
      liveOrderHoldable
      liveOrderHoldableIfPaid
      showProductImageInOrderLink,
      orderLinkTextShowProduct
    }
  }
`

export const GET_SETTING_SALEPAGE = gql`
  query {
    setting {
      id
      betaNewWebsite
      shopurl
      salePageBestSeller
      salePageNewArrival
      salePageFromStock
      facebookPageId
      facebookMessengerGreetingMsg
      salePageBanner1Url
      salePageBanner2Url
      salePageText
    }
  }
`
export const GET_SETTING_RESELLER = gql`
  query {
    setting {
      id
      hideStockFromReseller
      seperateResellerInvoiceNumber
      bypassResellerTransfer
      resellerFreeShipping
      showReselledPriceInInvoice
    }
  }
`

export const GET_SETTING_SHOPEE = gql`
  query {
    setting {
      id
      syncShopeeStock
      shopeeSyncOrder
      shopeeIncludeShippingFee
      shopeeSellerOwnFleetChangeTo
    }
  }
`

export const GET_SETTING_LAZADA = gql`
  query {
    setting {
      id
      syncLazadaStock
      lazadaSyncOrder
      lazadaIncludeShippingFee
    }
  }
`
export const GET_SETTING_WEBSITE = gql`
  query {
    setting {
      id
      apiUrl
      apiUser
      apiKey
      woocommerceWebhookUrl
    }
  }
`

export const GET_SETTING_CLOUNDFLARE = gql`
  query {
    setting {
      id
      cloudflareAuthKey
      cloudflareAuthEmail
    }
  }
`

export const GET_SETTING_FACEBOOKPIXEL = gql`
  query {
    setting {
      id
      facebookPixelTag
    }
  }
`

export const GET_SETTING_FACEBOOKVERIFYDOMAIN = gql`
  query {
    setting {
      id
      facebookDomainVerificationMetaContent
    }
  }
`

export const GET_SETTING_GOOGLEANALYSIS = gql`
  query {
    setting {
      id
      googleAnalyticsTag
    }
  }
`

export const GET_SETTING_USER = gql`
  query {
    setting {
      id
      name
      maxUsers
    }
  }
`

export const GET_SETTING_PERMISSION = gql`
  query {
    setting {
      id
      availableRoles
    }
  }
`

export const GET_SETTING_AVAILABLEBANKS = gql`
  query {
    setting {
      id
      availableBanks
    }
  }
`

export const GET_SETTING_BANKS = gql`
  query {
    setting {
      id
      banks
    }
  }
`

export const GET_JT_REGISTER_STATUS = gql`
  query {
    setting {
      jtRegisterStatus
    }
  }
`

export default {
  GET_SETTING_SHOPINFO,
  GET_SETTING_ORDER,
  GET_SETTING_LANGUAGE,
  GET_SETTING_PACKING,
  GET_SETTING_STOCK,
  GET_SETTING_PRODUCT,
  GET_SETTING_PAYMENT,
  GET_SETTING_TAXINVOICE,
  GET_SETTING_PROMOCODE,
  GET_SETTING_CRM,
  GET_SETTING_SHIPPING,
  GET_SETTING_RECEIPT,
  GET_SETTING_MESSAGE,
  GET_SETTING_SALEPAGE,
  GET_SETTING_LINKBILL,
  GET_SETTING_RESELLER,
  GET_SETTING_SHOPEE,
  GET_SETTING_LAZADA,
  GET_SETTING_WEBSITE,
  GET_SETTING_CLOUNDFLARE,
  GET_SETTING_FACEBOOKPIXEL,
  GET_SETTING_FACEBOOKVERIFYDOMAIN,
  GET_SETTING_GOOGLEANALYSIS,
  GET_SETTING_AVAILABLEBANKS,
  GET_SETTING_USER,
  GET_SETTING_PERMISSION,
  GET_SETTING_BANKS,
  GET_JT_REGISTER_STATUS
}
