import IfAuthenticated, { hasPermission } from '@/router/guards/auth.guard'

export default {
  path: '/shop-setting',
  component: {
    render (c) {
      return c('router-view')
    }
  },
  beforeEnter: IfAuthenticated,
  meta: { breadCrumb: 'shopsetting' },
  children: [
    {
      path: '',
      name: 'ShopSetting',
      component: () => import('@/views/ShopSetting/ShopSetting.vue')
    },
    {
      path: 'order',
      name: 'Order',
      component: () => import('@/views/ShopSetting/Pages/Order/Order.vue'),
      beforeEnter: (to, from, next) => hasPermission(next, 'settingOrderPage'),
      meta: { breadCrumb: 'order' }
    },
    {
      path: 'language',
      name: 'Language',
      component: () => import('@/views/ShopSetting/Pages/Language/Language.vue'),
      beforeEnter: (to, from, next) => hasPermission(next, 'settingI18n'),
      meta: { breadCrumb: 'language' }
    },
    {
      path: 'packing',
      name: 'Packing',
      component: () => import('@/views/ShopSetting/Pages/Packing/Packing.vue'),
      beforeEnter: (to, from, next) => hasPermission(next, 'settingPacking'),
      meta: { breadCrumb: 'packing' }
    },
    {
      path: 'warehouse',
      name: 'Warehouse',
      component: () => import('@/views/ShopSetting/Pages/Warehouse/Warehouse.vue'),
      beforeEnter: (to, from, next) => hasPermission(next, 'settingStock'),
      meta: { breadCrumb: 'warehouse' }
    },
    {
      path: 'product',
      name: 'Product',
      component: () => import('@/views/ShopSetting/Pages/Product/Product.vue'),
      beforeEnter: (to, from, next) => hasPermission(next, 'settingProduct'),
      meta: { breadCrumb: 'product' }
    },
    {
      path: 'payment',
      name: 'Payment',
      component: () => import('@/views/ShopSetting/Pages/Payment/Payment.vue'),
      meta: { breadCrumb: 'payment' }
    },
    {
      path: 'tax-invoice',
      name: 'TaxInvoice',
      beforeEnter: (to, from, next) => hasPermission(next, 'settingVat'),
      component: () => import('@/views/ShopSetting/Pages/TaxInvoice/TaxInvoice.vue'),
      meta: { breadCrumb: 'taxinvoice' }
    },
    {
      path: 'promocode',
      name: 'PromoCode',
      component: () => import('@/views/ShopSetting/Pages/Promocode/Promocode.vue'),
      beforeEnter: (to, from, next) => hasPermission(next, 'settingPromoCode'),
      meta: { breadCrumb: 'promocode' }
    },
    {
      path: 'crm',
      name: 'Crm',
      beforeEnter: (to, from, next) => hasPermission(next, 'settingCrm'),
      component: () => import('@/views/ShopSetting/Pages/Crm/Crm.vue'),
      meta: { breadCrumb: 'crm' }
    }
  ]
}
