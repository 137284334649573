import OrderHeader from '@/assets/svg/IconPageHeader/Order.svg'
import LanguuageHeader from '@/assets/svg/IconPageHeader/Language.svg'
import PackingHeader from '@/assets/svg/IconPageHeader/Packing.svg'
import WarehouseHeader from '@/assets/svg/IconPageHeader/Warehouse.svg'
import ProductHeader from '@/assets/svg/IconPageHeader/Product.svg'
import PaymentHeader from '@/assets/svg/IconPageHeader/Payment.svg'
import TaxinvoiceHeader from '@/assets/svg/IconPageHeader/Taxinvoice.svg'
import PromocodeHeader from '@/assets/svg/IconPageHeader/Promocode.svg'
import CrmHeader from '@/assets/svg/IconPageHeader/Crm.svg'

const pages = {
  orderHeader: {
    component: OrderHeader
  },
  languageHeader: {
    component: LanguuageHeader
  },
  packingHeader: {
    component: PackingHeader
  },
  warehouseHeader: {
    component: WarehouseHeader
  },
  productHeader: {
    component: ProductHeader
  },
  paymentHeader: {
    component: PaymentHeader
  },
  taxinvoiceHeader: {
    component: TaxinvoiceHeader
  },
  promocodeHeader: {
    component: PromocodeHeader
  },
  crmHeader: {
    component: CrmHeader
  },
}

export default pages