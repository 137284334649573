import gql from 'graphql-tag'
// creditCardHistory
export const GET_ROLE = gql`
  query ($id: ID!) {
    role(id: $id) {
      wasteProduct
      viewProductSet
      stageUnconfirmed
      stageRealPreorder
      stagePrintOrder
      stagePreorder
      stagePendingShippop
      stagePayLater
      stagePacking
      stageOpen
      stagePacked
      stageNotFullyPay
      stageHolding
      stageClosed
      stageCheckBank
      settingVat
      settingUnit
      settingShippingCalculator
      settingStock
      settingRole
      settingReseller
      settingPromoCode
      settingProduct
      settingPacking
      settingOrderPage
      settingManageUser
      settingManageMessage
      settingI18n
      settingCustomPrint
      settingCrm
      settingCreditCard
      settingBankAccount
      seeStockNumber
      seeResellerList
      returnProduct
      reportView
      reportCreate
      reportEdit
      reportFilter
      reportDelete
      reportMoveAndResize
      reportExport
      productView
      productRemoveStock
      productSeeCost
      productExcel
      productEdit
      productDelete
      productCreate
      productAddStock
      orderTransfer
      orderPrint
      orderPacking
      orderMultiplePrint
      orderExcel
      orderEdit
      orderDelete
      manageBarcode
      manageBrand
      manageCategory
      manageProductSet
      mergeOrder
      name
      orderAcceptReject
      orderClose
      orderConfirmShip
      orderCreate
      id
      holdOrder
      historyWastes
      historyVatInvoices
      historySms
      historyShipnityCredit
      historySendMessages
      historyShipments
      historyReturns
      historyReportCod
      historyAutoVerifies
      historyOrders
      historyExport
      historyExpenses
      historyCrm
      historyCreditCard
      historyCancelledOrders
      historyBatches
      historyAll
      deleteProductSet
      customerCategory
      createExpense
      contactSupplier
      contactCustomer
      changePassword
      accessShipmentMenu
      accessPos
      accessScope
      accessSetting
    }
  }
`

export default {
  GET_ROLE
}
