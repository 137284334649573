import gql from 'graphql-tag'
// import PageInfoFragments from '@/resources/graphql/fragment/PageInfo'

export const GET_USERS = gql`
  query ($page: Int, $perPage: Int) {
    users (page: $page, perPage: $perPage) {
      nodes {
        id
        email
        accessAllStock
        stocks {
          id
          name
        }
        role {
          id
          name
        }
      }
      nodesCount
      pagesCount
    }
  }
`

export default {}
