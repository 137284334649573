var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        color: _vm.color || _vm.type,
        timeout: _vm.timeout,
        "multi-line": _vm.multiLine,
        rounded: "pill",
        top: ""
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function(ref) {
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { icon: "", dark: "" },
                    on: {
                      click: function($event) {
                        return _vm.closeSnackbar()
                      }
                    }
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isSnackbar,
        callback: function($$v) {
          _vm.isSnackbar = $$v
        },
        expression: "isSnackbar"
      }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex snackbar--container" },
        [
          _c("v-icon", { attrs: { color: _vm.type, left: "", small: "" } }, [
            _vm._v(" " + _vm._s(_vm.icon) + " ")
          ]),
          _c("div", [_vm._v(" " + _vm._s(_vm.message) + " ")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }