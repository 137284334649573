<template>
  <div class="d-flex justify-space-between flex-column flex-sm-row">
    <text-field-label
      v-if="text"
      :label="text" />
    <v-spacer v-if="text" />
    <div class="mr-2 mt-3">
      <v-progress-circular
        v-if="loading"
        class="mr-2"
        size="16"
        width="2"
        indeterminate />
      <transition
        v-else
        name="fade">
        <v-img
          v-show="icon && !isTimeout"
          :src="icon"
          height="16"
          eager
          contain />
      </transition>
    </div>
    <v-select
      style="position: relative;"
      :value="value"
      :items="items"
      :item-value="itemValue"
      :item-text="itemText"
      :placeholder="placeholder"
      :attach="attach"
      :menu-props="menuProps"
      hide-details
      dense
      outlined
      single-line
      @input="$emit('input', $event)">
    </v-select>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [String, Number],
      default: () => ''
    },
    state: {
      type: String,
      validator: (val) => ['ready', 'loading', 'success', 'error'].includes(val),
      default: () => 'ready'
    },
    attach: {
      type: Boolean,
      default: false
    },
    menuProps: {
      type: [String, Object],
      default: 'auto'
    },
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: [String, Array, Function],
      default: ''
    },
    itemText: {
      type: [String, Array, Function],
      default: ''
    },
    text: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      timeOut: null,
      isTimeout: false
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    },
    icon () {
      switch (this.state) {
        case 'success':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        case 'error':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        default: return null
      }
    },
    isError () {
      return this.state === 'error'
    },
    color () {
      switch (this.state) {
        case 'ready':
        case 'loading':
          return 'primary'
        case 'success':
          return 'success'
        case 'error':
          return 'error'
        default: return 'primary'
      }
    }
  },
  watch: {
    state (value) {
      if (value === 'success') {
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
          this.isTimeout = true
        }, 3000)
      } else {
        this.isTimeout = false
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
