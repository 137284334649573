<template>
  <v-btn
    v-bind="$attrs"
    :color="color"
    depressed
    rounded
    :disabled="loading || $attrs.disabled"
    v-on="$listeners">
    <v-progress-circular
      v-if="loading"
      class="mr-2"
      size="16"
      width="2"
      indeterminate
    />
    <v-icon
      v-else
      class="mr-2"
      small>
      {{ icon }}
    </v-icon>
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      validator: (val) => ['ready', 'loading', 'success', 'error'].includes(val),
      default: () => 'ready'
    },
    label: {
      type: String,
      default: () => 'บันทึก'
    },
    prependIconReady: {
      type: String,
      default: () => '$save'
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    },
    icon () {
      switch (this.state) {
        case 'ready':
        case 'loading':
          return this.prependIconReady
        case 'success':
          return 'mdi-check-circle'
        case 'error':
          return 'mdi-close-circle'
        default: return this.prependIconReady
      }
    },
    isError () {
      return this.state === 'error'
    },
    color () {
      switch (this.state) {
        case 'ready':
        case 'loading':
          return 'primary'
        case 'success':
          return 'success'
        case 'error':
          return 'error'
        default: return 'primary'
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
