import EmptyStock from '@/assets/svg/IconDialog/empty_stock.svg'
import EyeWhite from '@/assets/svg/IconDialog/eye_white.svg'
import LogoutYellow from '@/assets/svg/IconDialog/logout_yellow.svg'
import TrashWhite from '@/assets/svg/IconDialog/trash_white.svg'

const dialogIcon = {
  emptystock: {
    component: EmptyStock
  },
  eyewhite: {
    component: EyeWhite
  },
  logoutyellow: {
    component: LogoutYellow
  },
  trashwhite: {
    component: TrashWhite
  }
}

export default dialogIcon
