import gql from 'graphql-tag'

export const CREATE_PAPERGROUP = gql`
  mutation CreatePaperGroup ($input: CreatePaperGroupInput!) {
    createPaperGroup (input: $input) {
      paperGroup {
        id
        name
      },
      errors
    }
  }
`

export const UPDATE_PAPERGROUP = gql`
  mutation UpdatePaperGroup ($input: UpdatePaperGroupInput!) {
    updatePaperGroup (input: $input) {
      paperGroup {
        id
        name
      },
      errors
    }
  }
`

export const DELETE_PAPERGROUP = gql`
  mutation DeletePaperGroup ($input: DeletePaperGroupInput!) {
    deletePaperGroup (input: $input) {
      clientMutationId
    }
  }
`

export default {
  CREATE_PAPERGROUP,
  UPDATE_PAPERGROUP,
  DELETE_PAPERGROUP
}
