import gql from 'graphql-tag'

export const CREATE_UNIT = gql`
  mutation createUnit ($input: CreateUnitInput!) {
    createUnit (input: $input) {
      unit {
        id
      }
      errors
    }
  }
`

export const UPDATE_UNIT = gql`
  mutation updateUnit ($input: UpdateUnitInput!) {
    updateUnit (input: $input) {
      unit {
        id
      }
      errors
    }
  }
`

export const DELETE_UNIT = gql`
  mutation deleteUnit ($input: DeleteUnitInput!) {
    deleteUnit (input: $input) {
      clientMutationId
    }
  }
`

export default {
  CREATE_UNIT,
  UPDATE_UNIT,
  DELETE_UNIT
}
