<template>
  <div class="d-flex text-subtitle-1 font-weight-medium secondary--text align-center">
    <v-icon
      v-if="prependIcon"
      class="mr-2"
      small>
      {{ prependIcon }}
    </v-icon>
    <span>{{ label }} </span>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    prependIcon: {
      type: String,
      default: () => null
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
