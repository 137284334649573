<template>
  <v-list
    id="app-sidebar"
    nav>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>
          mdi-cog-outline
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="title secondary--text">
          <span>{{ $t('app.header') }}</span>
        </div>
      </v-list-item-content>
      <v-list-item-icon>
        <spotlight />
      </v-list-item-icon>
    </v-list-item>
    <div class="px-3">
      <template v-for="item in sideBarMenu">
        <v-list-item
          v-if="item.show"
          :key="item.title"
          :to="item.to"
          :class="{ 'v-list-item--active': currentPage === item.name}"
          link

          @click="goToPage(item.name)">
          <v-list-item-icon>
            <v-icon v-if="currentPage === item.name">
              {{ item.icon.white }}
            </v-icon>
            <v-icon v-else>
              {{ item.icon.grey }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="secondary--text pt-1">
            {{ $t(item.title) }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </div>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import Spotlight from '@/components/Spotlight.vue'

export default {
  name: 'Sidebar',
  components: {
    Spotlight
  },
  data () {
    return {
      currentPage: 'ShopInfo',
      sideBarMenu: []
    }
  },
  computed: {
    ...mapGetters({
      permission: 'Auth/permission'
    }),
    namePath () {
      return this.$route.fullPath
    }
  },
  watch: {
    namePath () {
      if (this.$route.fullPath.match('shop-setting')) {
        this.currentPage = 'ShopSetting'
      } else if (this.$route.fullPath.match('papers')) {
        this.currentPage = 'Papers'
      } else {
        this.currentPage = this.$route.name
      }
    }
  },
  created () {
    this.sideBarMenu = [
      {
        id: '1',
        title: 'shopinfo.header',
        name: 'ShopInfo',
        icon: {
          white: '$w_shopinfo',
          grey: '$g_shopinfo'
        },
        to: '/',
        show: true
      },
      {
        id: '2',
        title: 'shopsetting.header',
        name: 'ShopSetting',
        icon: {
          white: '$w_shopsetting',
          grey: '$g_shopsetting'
        },
        to: '/shop-setting',
        show: true
      },
      {
        id: '3',
        title: 'shipping.header',
        name: 'Shipping',
        icon: {
          white: '$w_shipping',
          grey: '$g_shipping'
        },
        to: '/shipping',
        show: true
      },
      {
        id: '4',
        title: 'receipt.header',
        name: 'Receipt',
        icon: {
          white: '$w_receipt',
          grey: '$g_receipt'
        },
        to: '/receipt',
        show: this.permission.settingCustomPrint
      },
      {
        id: 'papers',
        title: 'papers.header',
        name: 'Papers',
        icon: {
          white: '$w_receipt',
          grey: '$g_receipt'
        },
        to: '/papers',
        show: this.permission.settingCustomPrint
      },
      {
        id: '5',
        title: 'message.header',
        name: 'Message',
        icon: {
          white: '$w_message',
          grey: '$g_message'
        },
        to: '/message',
        show: this.permission.settingManageMessage
      },
      {
        id: '6',
        name: 'LinkBill',
        title: 'linkbill.header',
        icon: {
          white: '$w_linkbill',
          grey: '$g_linkbill'
        },
        to: '/link-bill',
        show: true
      },
      {
        id: '7',
        title: 'connector.header',
        name: 'Connector',
        icon: {
          white: '$w_connector',
          grey: '$g_connector'
        },
        to: '/connector',
        show: true
      },
      {
        id: '8',
        title: 'salepage.header',
        name: 'SalePage',
        icon: {
          white: '$w_salepage',
          grey: '$g_salepage'
        },
        to: '/sale-page',
        show: true
      },
      {
        id: '9',
        title: 'agent.header',
        name: 'Agent',
        icon: {
          white: '$w_agent',
          grey: '$g_agent'
        },
        to: '/agent',
        show: this.permission.settingReseller
      },
      {
        id: '10',
        title: 'administrator.header',
        name: 'Administrator',
        icon: {
          white: '$w_administrator',
          grey: '$g_administrator'
        },
        to: '/administrator',
        show: this.permission.settingManageUser
      },
      {
        id: '11',
        title: 'account.header',
        name: 'Account',
        icon: {
          white: '$w_account',
          grey: '$g_account'
        },
        to: '/account',
        show: this.permission.changePassword
      }
    ]
  },
  methods: {
    goToPage (name) {
      this.currentPage = name
    }
  }
}
</script>

<style scoped>
  #app-sidebar .v-list-item {
    padding: 0;
    margin: 0;
  }
  #app-sidebar .v-list-item__icon {
    margin-left: 20px;
    margin-right: 10px;
  }
  #app-sidebar .v-list-item.v-list-item--link::before,
  #app-sidebar .v-list-item .v-list-item__icon .v-icon {
    transition: none !important;
  }
  #app-sidebar .v-list-item.v-list-item--active {
    color: var(--v-primary-base);
  }
  #app-sidebar .v-list-item.v-list-item--active .v-list-item__icon,
  #app-sidebar .v-list-item.v-list-item--active .v-list-item__content {
    color: #ffffff !important;
    z-index: 1;
  }
  #app-sidebar .v-list-item--active::before {
    opacity: 1;
  }
</style>
