var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex title secondary--text mb-4" },
    [
      _vm.prependIcon
        ? _c(
            "v-icon",
            { staticClass: "mt-2 mr-1", attrs: { size: _vm.prependIconSize } },
            [_vm._v(" " + _vm._s(_vm.prependIcon) + " ")]
          )
        : _vm._e(),
      _c("span", [_vm._v(" " + _vm._s(_vm.label) + " ")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }