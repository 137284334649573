export default {
  path: '/page-error',
  component: {
    render (c) {
      return c('router-view')
    }
  },
  meta: { breadCrumb: 'pageerror' },
  children: [
    {
      path: '403',
      name: 'AccessDenied',
      component: () => import('@/views/PageError/PageError.vue'),
      props: { statusCode: 403 }
    },
    {
      path: '404',
      name: 'NotFound',
      component: () => import('@/views/PageError/PageError.vue'),
      props: { statusCode: 404 }
    }
  ]
}
