<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    transition="scroll-y-transition"
    content-class="spotlight"
    hide-overlay
    @input="random($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        small
        v-on="on">
        <v-icon color="primary ">
          mdi-magnify
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-text-field
        v-model="query"
        :placeholder="placeholder"
        append-icon="mdi-magnify"
        autofocus
        hide-details
        outlined
        @keydown.enter="search()"
        @click:append="search()" />
    </v-card>
  </v-dialog>
</template>

<script>
import Chance from 'chance'

export default {
  data: () => ({
    dialog: false,
    placeholder: '',
    query: ''
  }),
  methods: {
    random (value) {
      if (value) {
        const chance = new Chance()
        const picks = chance.pickset(['สินค้า', 'ขนส่ง', 'ใบเสร็จ', 'ตัวแทน', 'Salepage', 'คลัง', 'สิทธิ์ผู้ใช้งาน'], 2)
        this.placeholder = this.$t('app.form.spotlight.placeholder', picks)
      }
    },
    search () {
      if (this.query) {
        window.location.href = `${process.env.VUE_APP_ROUTE_PREFIX}search?q=${this.query}`
        this.dialog = false
      }
    }
  }
}
</script>

<style>
.spotlight {
  position: fixed;
  top: 80px;
}
</style>
