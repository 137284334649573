import gql from 'graphql-tag'

export const GET_RESELLERS = gql`
  query ($q: String, $page: Int, $perPage: Int, $orderby: ResellerOrderBy, $activated: Boolean) {
    resellers(
      page: $page,
      perPage: $perPage,
      orderBy: $orderby,
      filter: { 
        activated: $activated, name: $q, OR: {
          activated: $activated, resellerCode: $q
        } 
      }
    ) {
      nodes {
        id
        name
        resellerCode
        pictureUrl
        rewardValue
        resellerType
        users {
          id
          email
          shopType
        }
        tier {
          id
          name
        }
      }
      nodesCount
      pagesCount
    }
  }
`

export default {}
