import Vue from 'vue'

const defaultState = {
  byId: {
    create: {
      id: 'create',
      name: 'create',
      values: []
    }
  },
  allIds: ['create']
}

const mutations = {
  ADD (state, payload) {
    const { id } = payload
    Vue.set(state.byId, id, {
      ...defaultState.byId.create,
      ...payload
    })
    state.allIds = Object.keys(state.byId)
  },
  UPDATE (state, payload) {
    const { id } = payload
    Vue.set(state.byId, id, {
      ...defaultState.byId.create,
      ...state.byId[id],
      ...payload
    })
    state.allIds = Object.keys(state.byId)
  }
}

const actions = {
  add ({ commit }, payload) {
    commit('ADD', {
      ...defaultState.byId.create,
      ...payload
    })
  },
  update ({ commit }, payload) {
    commit('UPDATE', payload)
  }
}

const getters = {
  find: (state) => (id) => state.byId[id],
  list: (state) => state.allIds.map((id) => state.byId[id])
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
