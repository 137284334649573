<template>
  <div class="d-flex justify-space-between flex-column flex-sm-row">
    <text-field-label
      :label="text" />
    <v-spacer />
    <div class="mr-2 mt-3">
      <v-progress-circular
        v-if="loading"
        size="16"
        width="2"
        indeterminate />
      <transition
        v-else
        name="fade">
        <v-img
          v-show="icon && !isTimeout"
          :src="icon"
          height="16"
          eager
          contain />
      </transition>
    </div>
    <div class="input-style">
      <v-text-field
        :value="value"
        :placeholder="placeholder"
        hide-details
        dense
        outlined
        @change="$emit('input', $event)" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: () => ''
    },
    state: {
      type: String,
      validator: (val) => ['ready', 'loading', 'success', 'error'].includes(val),
      default: () => 'ready'
    },
    text: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      timeOut: null,
      isTimeout: false
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    },
    icon () {
      switch (this.state) {
        case 'success':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        case 'error':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        default: return null
      }
    },
    isError () {
      return this.state === 'error'
    },
    color () {
      switch (this.state) {
        case 'ready':
        case 'loading':
          return 'primary'
        case 'success':
          return 'success'
        case 'error':
          return 'error'
        default: return 'primary'
      }
    }
  },
  watch: {
    state (value) {
      if (value === 'success') {
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
          this.isTimeout = true
        }, 3000)
      } else {
        this.isTimeout = false
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>
  .input-style {
    width: 560px;
  }
</style>
