import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import AuthModule from '@/store/modules/AuthModule'
import AppModule from '@/store/modules/AppModule'
import PaperBuilderModule from '@/store/modules/PaperBuilderModule'
import SettingModule from '@/store/modules/SettingModule'
import ThemeModule from '@/store/modules/ThemeModule'
import NotificationModule from '@/store/modules/NotificationModule'
import EnumModule from '@/store/modules/EnumModule'
import TableFieldModule from '@/store/modules/TableFieldModule'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: 'state:client',
  storage: window.localStorage,
  modules: ['Theme']
})

export default new Vuex.Store({
  modules: {
    Auth: AuthModule,
    App: AppModule,
    PaperBuilder: PaperBuilderModule,
    Setting: SettingModule,
    Theme: ThemeModule,
    Notification: NotificationModule,
    Enum: EnumModule,
    TableField: TableFieldModule
  },
  plugins: [
    vuexLocal.plugin
  ]
})
