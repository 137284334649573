<template>
  <v-breadcrumbs
    :items="[{
      text: 'app',
      to: '',
      icon: 'mdi-cog-outline',
    }, ...crumbs]"
    class="secondary--text px-0"
    large>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        exact>
        <v-icon
          v-if="item.icon"
          small
          class="mr-2">
          {{ item.icon }}
        </v-icon>
        {{ item.text ? $t(`${item.text}.header`) : item.path }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>

export default {
  name: 'Breadcrumb',
  computed: {
    crumbs () {
      const pathArray = this.$route.fullPath.split('/')
      pathArray.shift()
      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        if (this.$route.matched[idx]) {
          breadcrumbArray.push({
            path,
            to: breadcrumbArray[idx - 1]
              ? `/${ breadcrumbArray[idx - 1].path }/${ path }`
              : `/${ path }`,
            text: this.$route.matched[idx].meta.breadCrumb
          })
        }
        return breadcrumbArray
      }, [])

      return breadcrumbs
    }
  }
}
</script>
