var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "420" },
      model: {
        value: _vm.active,
        callback: function($$v) {
          _vm.active = $$v
        },
        expression: "active"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(" " + _vm._s(_vm.title || "") + " ")
          ]),
          _c("v-card-text", [
            _c("p", { staticClass: "white-space-pre" }, [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.message || "") } })
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              !_vm.confirmOnly
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(" " + _vm._s(_vm.cancelText || "") + " ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: _vm.confirmOnly ? _vm.confirmType : "success",
                    text: ""
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" " + _vm._s(_vm.confirmText || "") + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }