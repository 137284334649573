<template>
  <v-menu
    v-bind="$attrs"
    class="nav-dropdown"
    tile
    offset-y>
    <template v-slot:activator="{ attrs, on }">
      <slot
        name="btn"
        :attrs="attrs"
        :on="on">
        <v-btn
          class="nav-item nav-item-dropdown"
          text
          v-bind="attrs"
          v-on="on">
          {{ shopName }}
          <v-spacer />
          <v-icon small>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </slot>
    </template>
    <v-list class="nav-list-dropdown">
      <v-list-item
        v-if="isShipnity"
        :href="`https://blog.${domainName}/how-to/`"
        target="_blank"
        link>
        <v-list-item-title>
          คู่มือ
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isShipnity"
        :href="`https://package.${domainName}/?from=pro`"
        link>
        <v-list-item-title>สมัครแพ็คเกจ</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isShipnity"
        :href="`https://www.${domainName}/priceplan/buy/buy_sms`"
        link>
        <v-list-item-title>เติม Credit SMS</v-list-item-title>
      </v-list-item>
      <v-divider class="my-1" />
      <v-list-item
        :href="`https://www.${domainName}/users/edit`"
        link>
        <v-list-item-title>ตั้งค่าบัญชี</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        @click="logout()">
        <v-list-item-title>
          ออกจากระบบ
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import AuthProvider from '@/resources/AuthProvider'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      shipnityUrl: process.env.VUE_APP_SHIPNITY
    }
  },
  computed: {
    ...mapGetters({
      shopName: 'Setting/shopName'
    }),
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    },
    isShipnity () {
      return this.domainName === 'shipnity.com' || this.domainName === 'localhost'
    }
  },
  methods: {
    async logout () {
      AuthProvider.logout().catch(() => {
        window.location.href = this.shipnityUrl
      })
    }
  }
}
</script>
