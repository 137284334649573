import gql from 'graphql-tag'

export const CREATE_PROMOCODE = gql`
  mutation CreatePromoCode ($input: CreatePromoCodeInput!) {
    createPromoCode (input: $input) {
      promoCode {
        id
      }
      errors
    }
  }
`

export const UPDATE_PROMOCODE = gql`
  mutation UpdatePromoCode ($input: UpdatePromoCodeInput!) {
    updatePromoCode (input: $input) {
      promoCode {
        id
      }
      errors
    }
  }
`

export const DELETE_PROMOCODE = gql`
  mutation DeletePromoCode ($input: DeletePromoCodeInput!) {
    deletePromoCode (input: $input) {
      clientMutationId
    }
  }
`

export default {
  CREATE_PROMOCODE,
  UPDATE_PROMOCODE,
  DELETE_PROMOCODE
}
