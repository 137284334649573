import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: process.env.VUE_APP_MODE === 'PRODUCTION' ? 'th' : null
})

const loadedLanguages = []
function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export const i18nLoader = (lang) => {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }
  const pages = [
    'app',
    'pageerror',
    'shopinfo',
    'shopsetting',
    'shipping',
    'receipt',
    'papers',
    'message',
    'linkbill',
    'connector',
    'salepage',
    'agent',
    'administrator',
    'account',
    'search',
    'order',
    'language',
    'packing',
    'warehouse',
    'product',
    'payment',
    'taxinvoice',
    'promocode',
    'crm'
  ]
  const messages = {
    en: {},
    th: {}
  }
  const promises = pages.map((page) => axios.get(`${process.env.VUE_APP_ROUTE_PREFIX}locales/th/${page}.json`))
  return Promise.all(promises).then((locales) => {
    pages.forEach((page, i) => {
      messages.th[page] = locales[i].data
    })
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, messages[lang])
    return setI18nLanguage(lang)
  })
}

export default i18n
