import gql from 'graphql-tag'
// import PageInfoFragments from '@/resources/graphql/fragment/PageInfo'

export const GET_ROLES = gql`
  query ($page: Int, $perPage: Int) {
    roles (page: $page, perPage: $perPage) {
      nodes {
        id
        name
        users {
          id
        }
      }
      nodesCount
      pagesCount
    }
  }
`

export default {}
